import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Setting } from '../../../interfaces/setting';
import { AdminService } from '../../../services/admin.service'

import { Observable } from 'rxjs'

declare var $: any;

@Component({
  selector: 'fc-dashboard-actions',
  templateUrl: './dashboard-actions.component.html',
  styleUrls: ['./dashboard-actions.component.scss']
})
export class DashboardActionsComponent implements OnInit {
  @Input() homepageSettings: Setting[]
  @Input() needToRefreshDataEvent: Observable<void>;
  @Output() showToastEvent = new EventEmitter<any>()
  @Output() needToRefreshData = new EventEmitter<any>()

  actions: Setting[]
  actionForm: FormGroup
  actionFormSubmitted: boolean = false
  currentAction: Setting = null


  constructor(
    private formBuilder: FormBuilder,
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.filterSettings()
    this.initActionForm()
  }

  private filterSettings() {
    this.actions = []
    this.actions = this.homepageSettings.filter(function (e) {
      return e.key.startsWith('action-homepage')
    })
  }

  private initActionForm() {
    this.actionForm = this.formBuilder.group({
      target: ['', Validators.required]
    })
  }

  edit(action: Setting) {
    this.actionForm.reset()
    this.actionFormSubmitted = false
    this.currentAction = action

    this.actionForm = this.formBuilder.group({
      target: [this.currentAction.value, Validators.required]
    })

    setTimeout(() => {
      this.showModal()
    });
  }

  close() {
    this.hideModal()
    this.currentAction = null
  }

  onEditActionSubmit() {
    this.actionFormSubmitted = true;

    if (this.actionForm.invalid) {
      return
    }

    this.editAction()
  }

  editAction() {
    this.adminService.editSetting(
      parseInt(String(this.currentAction.id)),
      this.actionForm.controls.target.value,
      this.currentAction.displayName
    ).subscribe(editActionData => {
      this.needToRefreshData.emit()
      this.close()
      this.showToast("Action updated!")
    }, error => {
      console.error('there was an error sending the mutation', error);
      this.showToast("An error during the mutation has occured...")
    });
  }

  private showModal() {
    $("#black-overlay").addClass('visible')
    $("#edit-action-modal").modal('show');
  }

  private hideModal() {
    $("#black-overlay").removeClass('visible')
    $("#edit-action-modal").modal('hide');
  }

  private showToast(message: string): void {
    this.showToastEvent.emit(message)
  }
}
