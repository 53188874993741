import { Injectable } from '@angular/core';

import { Product } from '../interfaces/product'

import { Query } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends Query<Product> {
  document = gql`
    query product($productId: Int!) {
      product(productId: $productId) {
        id, sortId, eqi, name, image, width, height, depth, uom, notes, hasSellSheet, productColors { 
          fullEqi, color {
            id, code, name, image
          }
        }, bugs {
          id, name, imageUrl
        }, properties
      }
    }`;
}
