import { Injectable } from '@angular/core'

import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
    providedIn: 'root'
})
export class SettingsCUDService {
    constructor(private apollo: Apollo) { }

    createSetting(setting) {
        const createSetting = gql`
            mutation createSetting($setting: AddSettingInput!) {
                createSetting(setting: $setting) {
                    id
                }
            }`;

        return this.apollo.mutate({
            mutation: createSetting,
            variables: {
                setting: setting
            }
        })
    }

    editSetting(setting) {
        const updateSetting = gql`
            mutation updateSetting($setting: UpdateSettingInput!) {
                updateSetting(setting: $setting) {
                    id
            }
        }`;

        return this.apollo.mutate({
            mutation: updateSetting,
            variables: {
                setting: setting
            }
        })
    }

    deleteSetting(settingId: number) {
        const deleteSetting = gql`
            mutation deleteSetting($settingId: Float!) {
                deleteSetting(settingId: $settingId) 
            }`;

        return this.apollo.mutate({
            mutation: deleteSetting,
            variables: {
                settingId: settingId
            }
        })
    }
}