import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';

import { SharedService } from 'src/app/shared/services/shared.service'
import { CoreService } from 'src/app/core/services/core.service';
import { MenuCategory } from 'src/app/core/services/menu-categories.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/state/app.state';
import { DisplayStyle, ISiteSettings } from 'src/app/store/models/ISiteSettings';
import { EventBrokerService } from 'src/app/shared/services/eventBroker.service';

import { environment } from 'src/environments/environment'
import { ClientFunctions } from 'src/app/shared/interfaces/client-functions';

import { AuthService } from "src/app/public/auth/auth.service";
import { Observable, Subscription } from 'rxjs';

declare var dropdownHoverIntent: any;
declare var $: any;

@Component({
  selector: 'fc-menu-desktop',
  templateUrl: './menu.desktop.component.html',
  styleUrls: ['./menu.desktop.component.scss']
})
export class MenuDesktopComponent implements OnInit, OnDestroy {
  @Input() isFsvSecureAccess: boolean
  @Input() desktopMenuSubjectEvent: Observable<boolean>;
  @Output() menuLoaded = new EventEmitter<boolean>()

  menuCategoriesData: MenuCategory[];
  private desktopMenuSubjectSubscription: Subscription;
  private displayStyle: DisplayStyle = DisplayStyle.Cards;
  public isCardLayout = true;

  clientFunctions: ClientFunctions

  bigBetsLargeMenuCategoriesData: MenuCategory[];
  bigBetsSmallMenuCategoriesData: MenuCategory[];

  constructor(
    private store: Store<AppState>,
    public sharedService: SharedService,
    private eventBroker: EventBrokerService,
    private coreService: CoreService,
    public authService: AuthService
  ) {
    this
      .store
      .select(state => state.siteSettings)
      .subscribe((s: ISiteSettings) => {
        this.displayStyle = s ? s.displayStyle : DisplayStyle.Cards;
        this.isCardLayout = this.displayStyle === DisplayStyle.Cards;
      })
      ;
  }

  ngOnInit() {
    if (this.sharedService.environmentIsFSV()) {
      this.desktopMenuSubjectSubscription = this.desktopMenuSubjectEvent.subscribe((isFsvSecureAccess) => {
        this.getMenuCategories()
        this.desktopMenuSubjectSubscription.unsubscribe()
      });
    }

    this.clientFunctions = {
      mcoe: {
        inventoryClicked: () => {
          this.displayInventoryModal()
        },
        epopClicked: () => {
          window.open("https://epop.imsfastpak.com/default.aspx?ReturnUrl=%2fProductSearch.aspx", "_blank")
        }
      },
      fsv: {
        epopClicked: () => {
          window.open("https://epop.imsfastpak.com/default.aspx?ReturnUrl=%2fProductSearch.aspx", "_blank")
        }
      }
    }

    this.getMenuCategories()
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.sharedService.environmentIsFSV()) {
      this.desktopMenuSubjectSubscription.unsubscribe()
    }
  }

  private getMenuCategories(): void {
    this.coreService.getMenuCategories()
      .subscribe(menuCategoriesData => {
        this.menuCategoriesData = menuCategoriesData;

        // const largeFormatData = menuCategoriesData.find(x => x.name === "Large Format")?.children?.find(x => x.name === "Big Bets")
        // const largeFormatDataArray = []

        // if (largeFormatData) {
        //   largeFormatDataArray.push(largeFormatData)

        //   this.bigBetsLargeMenuCategoriesData = largeFormatDataArray
        // }

        // const smallFormatData = menuCategoriesData.find(x => x.name === "Small Format")?.children?.find(x => x.name === "Big Bets")
        // const smallFormatDataArray = []

        // if (smallFormatData) {
        //   smallFormatDataArray.push(smallFormatData)

        //   this.bigBetsSmallMenuCategoriesData = smallFormatDataArray
        // }

        new dropdownHoverIntent();

        this.menuLoaded.emit(true)
      }, error => {
        console.error('there was an error sending the query', error);
      });
  }

  navigateToCategoryId(categoryId: number) {
    this.sharedService.navigateToCategoryId(categoryId)
  }

  navigateToBugName(bugName: string) {
    this.sharedService.navigateToBugName(bugName)
  }

  displayInventoryModal() {
    $("#black-overlay").addClass('visible')
    $('#inventory-modal').modal('show');
  }

  switchDisplayStyle() {
    this.store.dispatch({
      type: 'SET_SITE_SETTINGS_DISPLAY_STYLE',
      payload: {
        displayStyle: (this.displayStyle === DisplayStyle.Cards)
          ? DisplayStyle.Rows
          : DisplayStyle.Cards
      }
    });

    setTimeout(() => {
      // Re-emit "productsLoaded" as it will re-compute the total height
      this.eventBroker.emit<void>('productsLoaded', null);
    }, 500);
  }

  openSellSheets() {
    window.open("https://pepsico.sharepoint.com/:f:/r/sites/FLNAAFHDistributors/Shared%20Documents/Merchandising/Frito%20Lay/FLNA%20Equipment%20Catalog/Equipment%20Sell%20Pages?csf=1&web=1&e=mbsIui", "_blank")
  }
}
