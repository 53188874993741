import { Injectable } from '@angular/core';

import { Product } from '../interfaces/product'

import { Query } from 'apollo-angular';
import gql from 'graphql-tag';

export interface ProductsByKeyword {
  productsByKeyword: Product[];
}

@Injectable({
  providedIn: 'root'
})
export class ProductsByKeywordService extends Query<ProductsByKeyword> {
  document = gql`
    query productsByKeyword($keyword: String!) {
      productsByKeyword(keyword: $keyword) {
        id, sortId, eqi, name, image, width, height, depth, uom, notes, hasSellSheet, productColors { 
          fullEqi, color {
            id, code, name, image
          }
        }, bugs {
          id, name, imageUrl
        }, properties
      }
    }`;
}
