import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router'

import { DevicesService } from 'src/app/core/services/devices.service';
import { SearchService } from 'src/app/core/services/search.service'
import { AdminService } from 'src/app/admin/services/admin.service'
import { EventBrokerService, IEventListener } from '../../shared/services/eventBroker.service';

import { PublicService } from '../services/public.service';
import { Category } from '../services/category.service'
import { Product } from '../interfaces/product'
import { SearchResult } from '../interfaces/search-result';
import { Store } from '@ngrx/store';
import { IBreadCrumb, IBreadCrumbCategory } from '../../store/models/IBreadCrumb';
import { AppState } from '../../store/state/app.state';
import { ISiteSettings, DisplayStyle } from 'src/app/store/models/ISiteSettings';

import { SharedService } from 'src/app/shared/services/shared.service';
import { ProductProperties } from '../interfaces/product-properties';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';

declare var $: any;

class HeightAndWidth {
  height: number;
  width: number;
}

@Component({
  selector: 'fc-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnDestroy {
  @Input() isPrint: boolean = false

  categoriesData: any[]
  printNumberOfPages = 0
  printBugHeadingPage1 = 0
  printBugHeadingPage2 = 0
  categoryDetails: {};
  categoryProducts: Product[] = [];
  categoryProducts2: Product[] = [];
  categoryChildren: Category[];
  bugReferenceName1 = ""
  bugReferenceName2 = ""
  isBugs: boolean = false
  isMobile: boolean = false
  displayLoader: boolean = false
  currentProductsSection = 'product-section-0';
  currentCategoryName = null
  currentCategoryChildName = null
  currentCategoryChildChildName = null
  isPrintFullCatalog: boolean = false
  downloadProduct: Product = null
  downloadCategoryId: number = null
  thumbnailProduct: Product = null
  thumbnailProductHas360 = false
  thumbnailImage = ""
  currentSearchType = 0
  isAlreadyLoaded: boolean = false
  pageHeight = 1580
  headerHeight = 92
  productsRowHeight = 412
  todayString: string
  sellSheetStatusData = null
  display360ProductViewerInThumbnailModal = false
  thumbnailMagnification = 1
  canListenMagnificationChange = false
  thumbnailInterval: NodeJS.Timeout
  canZoom = false
  public isCardLayout = true;
  private sectionChangedListener: IEventListener;
  private loadedEventListener: IEventListener;
  private subscriptions = new Subscription()

  constructor(
    private store: Store<AppState>,
    private eventBroker: EventBrokerService,
    private router: Router,
    private route: ActivatedRoute,
    private devicesService: DevicesService,
    private publicService: PublicService,
    private searchService: SearchService,
    public sharedService: SharedService,
    public adminService: AdminService
  ) {
    this.subscriptions.add(
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          if (this.isAlreadyLoaded) {
            this.loadProductsPage()
          }
        }
      })
    )

    this.sectionChangedListener = eventBroker
      .listen<{}>(
        'sectionChanged',
        (data: {}) => this.onSectionChange(data),
      )
      ;

    this
      .store
      .select(state => state.siteSettings)
      .subscribe((s: ISiteSettings) => {
        this.isCardLayout = s ? s.displayStyle === DisplayStyle.Cards : false;
        this.isBugs = s ? s.isBugs : false;
      })
      ;

    this.loadedEventListener = eventBroker
      .listen<void>(
        'productsLoaded',
        () => this.recomputeHeight()
      )
      ;

    this.loadedEventListener = eventBroker
      .listen<void>(
        'productsContainerSizeChanged',
        () => this.recomputeHeight()
      )
      ;
  }

  ngOnInit() {
    this.isMobile = this.devicesService.isMobile()
    this.isAlreadyLoaded = true
    this.loadProductsPage()
  }

  public ngOnDestroy() {
    this.sectionChangedListener.ignore();
    this.loadedEventListener.ignore();
    this.subscriptions.unsubscribe()
  }

  private recomputeHeight(): void {
    let h = 0;
    $('.categoryContainer').each((index, value) => {
      h += $(value).height();
    });

    $('.catalog-content').css({
      height: `${h}px`,
    });
    $('#products-scroll-spy').css({
      height: `${h}px`,
    });
  }

  private generateCurrentDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    this.todayString = mm + '/' + dd + '/' + yyyy;
  }

  private loadProductsPage(): void {
    this.adminService.getSettingByKey("pdf-sellSheet-status")
      .subscribe(settingByKeyData => {
        var valueParsed = JSON.parse(settingByKeyData.value)

        if (valueParsed['status'] !== undefined && valueParsed['status'] !== null) {
          this.sellSheetStatusData = valueParsed
        }
      }, error => {
        console.error('there was an error sending the query', error);
      });

    this.categoryProducts = []
    this.categoryProducts2 = []

    if (this.isPrint) {
      document.getElementById('wrapper').style.height = 'unset'

      this.generateCurrentDate()

      if (this.route.snapshot.paramMap.get('fullCatalog') === 'full') {
        this.isPrintFullCatalog = true
      } else {
        this.isPrintFullCatalog = false
      }

      let categoriesIdsUrl = this.route.snapshot.paramMap.get('categoriesIds')
      var categoriesIds = []
      let bugId = this.route.snapshot.paramMap.get('bugsIds')

      if (categoriesIdsUrl !== 'null') {
        let categoriesIdsUrlSplit = categoriesIdsUrl.split('&');

        categoriesIdsUrlSplit.forEach(categoryId => {
          let categoryIdToInt = parseInt(categoryId)

          categoriesIds.push(categoryIdToInt)
        });
      }

      this.getProductsByCategoriesAndBugs(categoriesIds, bugId)
    } else {
      if (this.route.snapshot.paramMap.get('type') === 'categories') {
        this.displayLoader = true
        this.store.dispatch({ type: 'SET_SITE_SETTINGS_BUGS', payload: { isBugs: false } });

        if (this.route.snapshot.paramMap.get('objectId') === 'all') {
          this.getAllCategoriesProducts(() => {
          })
        }
        // } else if (this.route.snapshot.paramMap.get('objectId') === 'bigbets') {
        //   if (!this.sharedService.canAccessBitBetsUrl) {
        //     this.router.navigateByUrl('/')
        //   } else {
        //     this.sharedService.disableAccessBitBetsUrl()

        //     this.subscriptions.add(
        //       this.publicService.getCategoriesL1()
        //         .pipe(
        //           take(1),
        //           map(categoriesL1Data => {
        //             var bigBetsCategories = {
        //               largeFormatCategoryId: null,
        //               smallFormatCategoryId: null
        //             }

        //             const largeFormatData = categoriesL1Data.find(x => x.name === "Large Format")?.children?.find(x => x.name === "Big Bets")
        //             const smallFormatData = categoriesL1Data.find(x => x.name === "Small Format")?.children?.find(x => x.name === "Big Bets")

        //             if (largeFormatData && smallFormatData) {
        //               bigBetsCategories.largeFormatCategoryId = largeFormatData.id
        //               bigBetsCategories.smallFormatCategoryId = smallFormatData.id
        //             }

        //             return bigBetsCategories
        //           }),
        //           filter(bigBetsCategories => {
        //             if (bigBetsCategories.largeFormatCategoryId !== null && bigBetsCategories.smallFormatCategoryId !== null) {
        //               return true
        //             }

        //             this.router.navigateByUrl('/')
        //             return false
        //           }),
        //           switchMap(bigBetsCategories => combineLatest([
        //             this.publicService.getCategoryWithProducts(parseInt(bigBetsCategories.largeFormatCategoryId)),
        //             this.publicService.getCategoryWithProducts(parseInt(bigBetsCategories.smallFormatCategoryId))
        //           ])
        //             .pipe(
        //               map(([bigBetsLargeProducts, bigBetsSmallProducts]) => {
        //                 let categoryDataArray = []
        //                 categoryDataArray.push(bigBetsLargeProducts)
        //                 categoryDataArray.push(bigBetsSmallProducts)

        //                 categoryDataArray.forEach(element => {
        //                   if (element.products.length > 0) {
        //                     this.sort(element)
        //                   }

        //                   element.children.forEach(elementChild => {
        //                     if (elementChild.products.length > 0) {
        //                       this.sort(elementChild)
        //                     }

        //                     elementChild.children.forEach(elementChildChild => {
        //                       if (elementChildChild.products.length > 0) {
        //                         this.sort(elementChildChild)
        //                       }
        //                     });
        //                   });
        //                 });

        //                 const fakeBigBetsCategoryForBreadCrumb: IBreadCrumbCategory = {
        //                   id: "0",
        //                   name: "Big Bets"
        //                 }

        //                 const breadCrumb: IBreadCrumb = {
        //                   category: fakeBigBetsCategoryForBreadCrumb,
        //                   categoryChild: null,
        //                   categoryChildChild: null,
        //                 };

        //                 this.store.dispatch({
        //                   type: 'SET_BREADCRUMB',
        //                   payload: breadCrumb,
        //                 });

        //                 this.categoriesData = categoryDataArray

        //                 this.currentCategoryName = null
        //                 this.currentCategoryChildName = null
        //                 this.currentCategoryChildChildName = null

        //                 if (this.categoriesData.length > 0) {
        //                   this.currentCategoryName = this.categoriesData[0]['name']

        //                   if (this.categoriesData[0]['children'].length > 0) {
        //                     this.currentCategoryChildName = this.categoriesData[0]['children'][0]['name']
        //                   }
        //                 }

        //                 this.scrollTop()
        //               })
        //             ))
        //         )
        //         .subscribe(() => {
        //           this.displayLoader = false
        //           setTimeout(() => {
        //             this.eventBroker.emit<void>('productsLoaded', null);
        //           }, 500);
        //         })
        //     )
        //   }
        // } 
        else {
          this.getCategoryProducts(parseInt(this.route.snapshot.paramMap.get('objectId')), true, () => {
            this.displayLoader = false
            setTimeout(() => {
              this.eventBroker.emit<void>('productsLoaded', null);
            }, 500);
          })
        }
      } else if (this.route.snapshot.paramMap.get('type') === 'bugs') {
        this.displayLoader = true
        this.store.dispatch({ type: 'SET_SITE_SETTINGS_BUGS', payload: { isBugs: true } });
        this.getBugProducts(parseInt(this.route.snapshot.paramMap.get('objectId')), true, () => {
          setTimeout(() => {
            this.eventBroker.emit<void>('productsLoaded', null);
          }, 500);
        })
      } else if (this.route.snapshot.paramMap.get('type') === 'search') {
        this.displayLoader = true
        this.store.dispatch({ type: 'SET_SITE_SETTINGS_BUGS', payload: { isBugs: true } });

        this.searchByKeyword(decodeURIComponent(this.route.snapshot.paramMap.get('objectId')))
      } else if (this.route.snapshot.paramMap.get('type') === 'colors') {
        this.displayLoader = true
        this.store.dispatch({ type: 'SET_SITE_SETTINGS_BUGS', payload: { isBugs: true } });
        this.getProductsByColor(parseInt(this.route.snapshot.paramMap.get('objectId')), true, () => {
        })
      } else if (this.route.snapshot.paramMap.get('type') === 'product') {
        this.displayLoader = true
        this.store.dispatch({ type: 'SET_SITE_SETTINGS_BUGS', payload: { isBugs: true } });
        this.getProductById(parseInt(this.route.snapshot.paramMap.get('objectId')), true, () => {
        })
      }
    }
  }

  private getAllCategoriesProducts(_callback): void {
    this.publicService.getAllCategoriesWithProducts()
      .subscribe(categoriesData => {
        this.generateSingleBreadcrumb({ name: 'Full Catalog', id: 'all' })

        categoriesData.forEach(element => {
          if (element.products.length > 0) {
            this.sort(element)
          }

          element.children.forEach(elementChild => {
            if (elementChild.products.length > 0) {
              this.sort(elementChild)
            }

            elementChild.children.forEach(elementChildChild => {
              if (elementChildChild.products.length > 0) {
                this.sort(elementChildChild)
              }
            });
          });
        });

        this.categoriesData = categoriesData
        this.currentCategoryName = null
        this.currentCategoryChildName = null
        this.currentCategoryChildChildName = null

        if (this.categoriesData.length > 0) {
          this.currentCategoryName = this.categoriesData[0]['name']

          if (this.categoriesData[0]['children'].length > 0) {
            this.currentCategoryChildName = this.categoriesData[0]['children'][0]['name']
          }
        }

        this.scrollTop()

        this.displayLoader = false
        setTimeout(() => {
          this.eventBroker.emit<void>('productsLoaded', null);
        }, 500);

        _callback()
      }, error => {
        this.displayLoader = false
        console.error('there was an error sending the query', error);
      });
  }

  private getCategoryProducts(categoryId: number, isSingleSearch: boolean, _callback): void {
    this.publicService.getCategoryWithProducts(categoryId)
      .subscribe(categoryData => {
        if (isSingleSearch) {
          let categoryDataArray = []
          categoryDataArray.push(categoryData)

          categoryDataArray.forEach(element => {
            if (element.products.length > 0) {
              this.sort(element)
            }

            element.children.forEach(elementChild => {
              if (elementChild.products.length > 0) {
                this.sort(elementChild)
              }

              elementChild.children.forEach(elementChildChild => {
                if (elementChildChild.products.length > 0) {
                  this.sort(elementChildChild)
                }
              });
            });
          });

          this.generateMultipleBreadcrumb(categoryData)
          this.categoriesData = categoryDataArray

          this.currentCategoryName = null
          this.currentCategoryChildName = null
          this.currentCategoryChildChildName = null

          if (this.categoriesData.length > 0) {
            this.currentCategoryName = this.categoriesData[0]['name']

            if (this.categoriesData[0]['children'].length > 0) {
              this.currentCategoryChildName = this.categoriesData[0]['children'][0]['name']
            }
          }

          this.scrollTop()

          _callback()
        } else {
          this.categoryProducts.push(...categoryData.products)

          categoryData['children'].forEach(categoryDataChildren => {
            if (categoryDataChildren.products.length > 0) {
              this.categoryProducts.push(...categoryDataChildren.products)
            }

            categoryDataChildren['children'].forEach(categoryDataChildrenChildren => {
              if (categoryDataChildrenChildren.products.length > 0) {
                this.categoryProducts.push(...categoryDataChildrenChildren.products)
              }
            });
          });

          _callback()
        }
      }, error => {
        this.displayLoader = false
        console.error('there was an error sending the query', error);
      });
  }

  private getProductsByCategoriesAndBugs(categoriesIds: number[], bugId: string): void {
    var previousHeadingName = ""

    if (categoriesIds.length > 0) {
      const publicSubscription = this.publicService.getProductsByCategories(categoriesIds)
        .subscribe(getCategoriesWithProductsData => {
          // Flat all categories into one array with specifics types
          // Group products by array of 3 objects (representing 1 row)
          var printData = []
          var getCategoriesWithProductsDataSorted = [];

          categoriesIds.forEach(el => getCategoriesWithProductsDataSorted.push(getCategoriesWithProductsData.find(e => e.id == el)));

          getCategoriesWithProductsDataSorted.forEach(firstParent => {
            var headingName = firstParent.name

            if (firstParent.parent) {
              headingName = firstParent.parent.name

              if (firstParent.parent.parent) {
                headingName = firstParent.parent.parent.name
              }
            }

            if (headingName !== previousHeadingName) {
              printData.push({
                type: 'heading',
                height: this.pageHeight,
                name: headingName,
                firstParentName: firstParent.name
              })
            }

            previousHeadingName = headingName

            generateHeaderAndProductsArray(firstParent, null, null, this.headerHeight, this.productsRowHeight)
            firstParent['children'].forEach(secondParent => {
              generateHeaderAndProductsArray(secondParent, firstParent, null, this.headerHeight, this.productsRowHeight)
              secondParent['children'].forEach(thirdParent => {
                generateHeaderAndProductsArray(thirdParent, secondParent, firstParent, this.headerHeight, this.productsRowHeight)
              });
            });
          });

          function sort(element) {
            if (element.products.length > 0) {
              element.products.sort(function (a, b) {
                return a.sortId - b.sortId;
              });
            }
          }

          function generateHeaderAndProductsArray(element, elementParent, elementParentParent, headerHeight, productsRowHeight) {
            sort(element)

            var categoryName = ''
            var categoryChildName = ''
            var categoryChildChildName = ''

            if (elementParentParent != null && elementParent !== null) {
              categoryName = elementParentParent.name
              categoryChildName = elementParent.name
              categoryChildChildName = element.name
            } else if (elementParentParent == null && elementParent !== null) {
              categoryName = elementParent.name
              categoryChildName = element.name
              categoryChildChildName = null
            } else {
              categoryName = element.name
              categoryChildName = null
              categoryChildChildName = null
            }

            if (element.products.length > 0) {
              printData.push({
                type: 'header',
                height: headerHeight,
                id: element.id,
                name: element.name,
                categoryName: categoryName,
                categoryChildName: categoryChildName,
                categoryChildChildName: categoryChildChildName
              })

              while (element.products.length) {
                printData.push({
                  type: "products",
                  height: productsRowHeight,
                  data: element.products.splice(0, 3)
                })
              }
            }
          }

          // Group header and first line of row together into 1 block

          const printDataClone = [...printData]
          var printDataGroup = []

          var i = 0
          while (i < printDataClone.length) {
            let currentObject = printDataClone[i]

            if (currentObject['type'] === 'header') {
              printDataGroup.push({
                type: "headerAndProducts",
                height: this.headerHeight + this.productsRowHeight,
                data: printDataClone.slice(i, i + 2)
              })

              i++
            } else {
              printDataGroup.push(printDataClone.slice(i, i + 1)[0])
            }

            i++
          }

          // Calculate height for each elements and generate appropiate pages

          this.calculElementsHeightAndGeneratePages('products', printDataGroup, 0, (printDataPage, currentPage) => {
            this.categoriesData = printDataPage
            this.printNumberOfPages = currentPage

            this.generateBugsData(bugId, publicSubscription, currentPage)

            /*if (bugId !== null && bugId === "special-offers") {
              publicSubscription.unsubscribe()
              this.publicService.getBugProducts(4)
                .subscribe(bugData => {
                  this.groupBugsBy3(bugData, currentPage, "bug1", () => {
                  })
                }, error => {
                  console.error('there was an error sending the query', error);
                });
            }*/
          })
        }, error => {
          console.error('there was an error sending the query', error);
        });
    } else {
      this.generateBugsData(bugId, null, 0)
    }
  }

  private generateBugsData(bugId, subscription, pageNumber) {
    if (bugId !== null) {
      var bugReferenceName1 = null
      var bugProductsId1 = null
      var bugReferenceName2 = null
      var bugProductsId2 = null

      switch (bugId) {
        case "special-offers":
          bugReferenceName1 = "Special Offers"
          bugProductsId1 = 4
          break;
        case "header-cards":
          bugReferenceName1 = "Header Cards"
          bugProductsId1 = 4
          break;
        case "accessories":
          bugReferenceName1 = "Accessories"
          bugProductsId1 = 3
          break;
        case "accessories&header-cards":
          bugReferenceName1 = "Accessories"
          bugProductsId1 = 3
          bugReferenceName2 = "Header Cards"
          bugProductsId2 = 4
          break;
        case "header-cards&accessories":
          bugReferenceName1 = "Header Cards"
          bugProductsId1 = 4
          bugReferenceName2 = "Accessories"
          bugProductsId2 = 3
          break;
      }

      if (bugReferenceName1 !== null && bugProductsId1 !== null) {
        if (subscription !== null) {
          console.log('unsubscribe', subscription)
          subscription.unsubscribe()
        }

        this.publicService.getBugProducts(bugProductsId1)
          .subscribe(bugData => {
            this.groupBugsBy3(bugData, pageNumber, "bug1", (currentPage) => {
              this.bugReferenceName1 = bugReferenceName1

              if (bugReferenceName2 !== null && bugProductsId2 !== null) {
                this.publicService.getBugProducts(bugProductsId2)
                  .subscribe(bugData => {
                    this.groupBugsBy3(bugData, currentPage, "bug2", (currentPage) => {
                      this.bugReferenceName2 = bugReferenceName2
                    })
                  }, error => {
                    console.error('there was an error sending the query', error);
                  });
              }
            })
          }, error => {
            console.error('there was an error sending the query', error);
          });
      }
    }
  }

  private groupBugsBy3(bugData, page, bugsNumber, _callback) {
    const bugDataClone = [...bugData]
    var printBugsData = []

    while (bugDataClone.length) {
      printBugsData.push({
        type: "products",
        height: this.productsRowHeight,
        data: bugDataClone.splice(0, 3)
      })
    }

    this.calculElementsHeightAndGeneratePages(bugsNumber, printBugsData, page, (printDataPage, currentPage) => {
      if (bugsNumber === "bug1") {
        this.categoryProducts = printDataPage;
      } else if (bugsNumber === "bug2") {
        this.categoryProducts2 = printDataPage
      }

      this.printNumberOfPages = currentPage

      _callback(currentPage)
    })
  }

  private calculElementsHeightAndGeneratePages(itemType, printDataGroup, page, _callback) {
    var printDataPage = []
    var currentHeight = 0
    var currentPage = page

    if (itemType === 'bug1') {
      currentPage = page += 1
      this.printBugHeadingPage1 = currentPage
      generateHeader(null)
    } else if (itemType === 'bug2') {
      currentPage = page += 1
      this.printBugHeadingPage2 = currentPage
      generateHeader(null)
    }

    for (let index = 0; index < printDataGroup.length; index++) {
      const element = printDataGroup[index];

      if (element['type'] === 'heading') {
        generateHeader(element)
        currentPage++

        printDataPage.push({
          type: "header",
          value: currentPage
        })
      } else {
        if (currentHeight + element['height'] + 50 <= this.pageHeight) {
          printDataPage.push(element)

          currentHeight += element['height']
        } else {
          generateHeader(element)
          currentHeight += element['height']
        }
      }
    }

    function generateHeader(element) {
      currentPage++

      printDataPage.push({
        type: "header",
        value: currentPage
      })

      if (element !== null) {
        printDataPage.push(element)
      }

      currentHeight = 0
    }

    _callback(printDataPage, currentPage)
  }

  private getBugProducts(bugId: number, isSingleSearch: boolean, _callback): void {
    this.publicService.getBugProducts(bugId)
      .subscribe(bugData => {
        if (isSingleSearch) {
          this.categoryProducts = bugData;
          this.scrollTop()
          this.displayLoader = false

          _callback()
        } else {
          this.categoryProducts.push(...bugData)
          this.displayLoader = false

          _callback()
        }
      }, error => {
        this.displayLoader = false
        console.error('there was an error sending the query', error);
      });
  }

  private getProductsByKeyword(keyword: string, isSingleSearch: boolean, _callback): void {
    this.publicService.getProductsByKeyword(keyword)
      .subscribe(productsByKeywordData => {
        if (isSingleSearch) {
          this.categoryProducts = productsByKeywordData;
          this.scrollTop()
          this.displayLoader = false

          _callback()
        } else {
          this.categoryProducts.push(...productsByKeywordData)
          this.displayLoader = false

          _callback()
        }
      }, error => {
        this.displayLoader = false
        console.error('there was an error sending the query', error);
      });
  }

  private getProductsByColor(colorId: number, isSingleSearch: boolean, _callback): void {
    this.publicService.getProductsByColor(colorId)
      .subscribe(productsByColorData => {
        if (isSingleSearch) {
          this.categoryProducts = productsByColorData;
          this.scrollTop()
          this.displayLoader = false

          _callback()
        } else {
          this.categoryProducts.push(...productsByColorData)
          this.displayLoader = false

          _callback()
        }
      }, error => {
        this.displayLoader = false
        console.error('there was an error sending the query', error);
      });
  }

  private getProductById(productId: number, isSingleSearch: boolean, _callback) {
    this.publicService.getProductByid(productId)
      .subscribe(productByIdData => {
        var productByIdDataArray = []
        productByIdDataArray.push(productByIdData)

        if (isSingleSearch) {
          this.categoryProducts = productByIdDataArray;
          this.scrollTop()
          this.displayLoader = false

          _callback()
        } else {
          this.categoryProducts.push(...productByIdDataArray)
          this.displayLoader = false

          _callback()
        }
      }, error => {
        this.displayLoader = false
        console.error('there was an error sending the query', error);
      });
  }

  private searchByKeyword(searchKeyword: string) {
    this.searchService.searchTerm(searchKeyword)
      .subscribe(searchResults => {
        /*var searchResultsFiltered = searchResults.map(searchResult => searchResult)
          .filter((value, index, self) => value.type !== 'Product')*/

        this.currentSearchType = 0
        this.scrollTop()
        this.loopOverSearchTypes(searchResults)
      }, error => {
        console.error('there was an error sending the search', error);
      });
  }

  private loopOverSearchTypes(searchResultsTypes: SearchResult[]) {
    if (searchResultsTypes.length > 0) {
      if (searchResultsTypes[this.currentSearchType].type === 'Category') {
        this.getCategoryProducts(searchResultsTypes[this.currentSearchType].id, false, () => {
          this.checkNextSearchType(searchResultsTypes)
        })
      } else if (searchResultsTypes[this.currentSearchType].type === 'Bug') {
        this.getBugProducts(searchResultsTypes[this.currentSearchType].id, false, () => {
          this.checkNextSearchType(searchResultsTypes)
        })
      } else if (searchResultsTypes[this.currentSearchType].type === 'Color') {
        this.getProductsByColor(searchResultsTypes[this.currentSearchType].id, false, () => {
          this.checkNextSearchType(searchResultsTypes)
        })
      } else if (searchResultsTypes[this.currentSearchType].type === 'Product') {
        this.getProductById(searchResultsTypes[this.currentSearchType].id, false, () => {
          this.checkNextSearchType(searchResultsTypes)
        })
      }
    } else {
      this.checkNextSearchType([])
    }

    setTimeout(() => {
      this.eventBroker.emit<void>('productsLoaded', null);
    }, 500)
  }

  private checkNextSearchType(searchResultsTypes: SearchResult[]) {
    if (searchResultsTypes[this.currentSearchType + 1] !== undefined) {
      this.currentSearchType++

      this.loopOverSearchTypes(searchResultsTypes)
    } else {
      this.getProductsByKeyword(decodeURIComponent(this.route.snapshot.paramMap.get('objectId')), false, () => {
        this.categoryProducts = this.categoryProducts.filter((value, index, self) => self.findIndex(product => (product.id === value.id)) === index)

        $(".search-button-image").css('display', 'block')
        $(".search-spinner").css('display', 'none')
      })
    }
  }

  private generateSingleBreadcrumb(category: IBreadCrumbCategory): void {
    const breadCrumb: IBreadCrumb = {
      category: category,
      categoryChild: null,
      categoryChildChild: null,
    };

    this.store.dispatch({
      type: 'SET_BREADCRUMB',
      payload: breadCrumb,
    });
  }

  private generateMultipleBreadcrumb(categoryData: any): void {
    const breadCrumb: IBreadCrumb = {
      category: null,
      categoryChild: null,
      categoryChildChild: null,
    };

    if (categoryData.parent === null) {
      breadCrumb.category = categoryData;
    } else {
      if (categoryData.parent) {
        if (categoryData.parent.parent !== null) {
          breadCrumb.categoryChildChild = categoryData;
          breadCrumb.categoryChild = {
            id: categoryData.parent.id.toString(),
            name: categoryData.parent.name,
            parent: categoryData.parent.parent,
          };
          breadCrumb.category = {
            id: categoryData.parent.parent.id.toString(),
            name: categoryData.parent.parent.name,
            parent: categoryData.parent.parent.parent,
          };
        } else {
          breadCrumb.categoryChild = categoryData;
          breadCrumb.category = {
            id: categoryData.parent.id.toString(),
            name: categoryData.parent.name,
            parent: categoryData.parent.parent,
          };
        }
      } else {
        breadCrumb.category = categoryData;
      }
    }

    this.store.dispatch({
      type: 'SET_BREADCRUMB',
      payload: breadCrumb,
    });
  }

  onSectionChange(currentSectionData: {}) {
    this.currentProductsSection = currentSectionData["sectionId"];
    this.currentCategoryName = currentSectionData["categoryName"];
    this.currentCategoryChildName = currentSectionData["categoryChildName"];
    this.currentCategoryChildChildName = currentSectionData["categoryChildChildName"];
  }

  onDownloadCardClicked(content: any) {
    this.downloadProduct = content.product
    this.downloadCategoryId = content.categoryId

    setTimeout(() => {
      $("#black-overlay").addClass('visible')
      $('#download-modal').modal('show')
    }, 500);
  }

  onThumbnailClicked(product: Product) {
    this.thumbnailProduct = product

    try {
      const productProperties: ProductProperties = JSON.parse(product.properties)

      this.thumbnailProductHas360 = productProperties.has360View === true ? true : false
    } catch (e) {
      this.thumbnailProductHas360 = false
    }

    if (this.thumbnailProduct.productColors[0].fullEqi !== null) {
      this.thumbnailImage = `${this.sharedService.getEnvironmentContainer()}/images-products/jpeg/${this.thumbnailProduct.productColors[0].fullEqi}.jpg`
    } else {
      this.thumbnailImage = `${this.sharedService.getEnvironmentContainer()}/images-products/jpeg/${this.thumbnailProduct.eqi}.jpg`
    }

    setTimeout(() => {
      $("#black-overlay").addClass('visible')
      $('#thumbnail-modal').modal('show')

      this.thumbnailMagnification = 1.5
      this.canListenMagnificationChange = true

      const modalBodyElement = (document.querySelector('#thumbnail-modal .modal-body') as HTMLElement)
      const ngxImageZoomFullElement = document.querySelector('.ngxImageZoomFull') as HTMLImageElement

      if (!ngxImageZoomFullElement || !modalBodyElement) { return }

      this.thumbnailInterval = setInterval(() => {
        if (modalBodyElement.offsetHeight !== 0 && ngxImageZoomFullElement.height !== 0) {
          clearInterval(this.thumbnailInterval)
          this.thumbnailMagnification = (modalBodyElement.offsetHeight / ngxImageZoomFullElement.height) + 0.5
          this.canZoom = true
        }
      }, 500);
    }, 500);
  }

  closeThumbnailModal() {
    $("#black-overlay").removeClass('visible')
    $('#thumbnail-modal').modal('hide');
    this.closeFritolayProductViewer()
    this.thumbnailProduct = undefined
    this.canListenMagnificationChange = false
    this.canZoom = false
    if (this.thumbnailInterval) {
      clearInterval(this.thumbnailInterval)
    }
  }

  private scrollTop() {
    setTimeout(() => {
      $('.container.content').scrollTop(0)

      if (document.getElementById('products-scroll-spy') !== null) {
        document.getElementById('products-scroll-spy').scrollBy(0, 1)
        $(".main-container").scrollTop(1);
      }
    }, 0);
  }

  private sort(element) {
    if (element.products.length > 0) {
      element.products.sort(function (a, b) {
        return a.sortId - b.sortId;
      });
    }
  }

  button360ClickedEmitter(): void {
    if (!this.display360ProductViewerInThumbnailModal) {
      this.display360ProductViewerInThumbnailModal = true

      if (!document.getElementById('button-360')) { return }
      document.getElementById('button-360').classList.add('active')
    } else {
      this.closeFritolayProductViewer()
    }
  }

  closeFritolayProductViewer(): void {
    this.display360ProductViewerInThumbnailModal = false

    if (!document.getElementById('button-360')) { return }
    document.getElementById('button-360').classList.remove('active')
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.canListenMagnificationChange) {
      const modalBodyElement = (document.querySelector('#thumbnail-modal .modal-body') as HTMLElement)
      const ngxImageZoomFullElement = document.querySelector('.ngxImageZoomFull') as HTMLImageElement

      if (!ngxImageZoomFullElement || !modalBodyElement) { return }

      this.thumbnailMagnification = (modalBodyElement.offsetHeight / ngxImageZoomFullElement.height) + 0.5
    }
  }
}
