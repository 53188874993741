<div class="modal fade" id="download-modal" tabindex="-1" role="dialog" aria-labelledby="downloadModal"
    aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-10">
                        <div class="eqi">
                            {{ product.eqi }}
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="modal-title">
                            <button type="button" class="close" aria-label="Close" (click)="close()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 title-text">
                        <div class="title">
                            {{ product.name }}
                        </div>
                    </div>
                    <div class="col-12 mb-4">
                        <div class="separator"></div>
                    </div>
                </div>
                <div class="row mb-4" *ngIf="showSellSheetLink">
                    <div class="col-12">
                        <div class="sellsheet">
                            <button class="btn btn-1" (click)="openSellSheet()">Download Sell Sheet</button>
                        </div>
                    </div>
                </div>
                <div class="row asset">
                    <div class="col-4">
                        <div class="img-container">
                            <img [src]="imageName" class="card-img-top">
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="row h-100">
                            <div class="col-12 h-50">
                                <div class="description">
                                    RENDERING
                                    <br>
                                    {{ product.eqi}} {{ product.name}}
                                </div>
                            </div>
                            <div class="col-12 download h-50">
                                <div class="download">
                                    <button type="button" class="btn btn-2 btn-outline ga-clic-download"
                                        (click)="download()" [attr.data-titlega]="product.eqi + ' - ' + product.name">
                                        Download
                                        <img src="assets/images/products/download.PNG" class="ga-clic-download"
                                            [attr.data-titlega]="product.eqi + ' - ' + product.name" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="canDisplayZipButton">
                    <div class="col-12 mt-4 mb-2">
                        <div class="zip">
                            <button type="button" class="btn btn-2 btn-outline ga-clic-download" (click)="downloadZip()"
                                [attr.data-titlega]="product.eqi + ' - ' + product.name">
                                Download ZIP assets
                                <img src="assets/images/products/download.PNG" class="ga-clic-download"
                                    [attr.data-titlega]="product.eqi + ' - ' + product.name" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>