<div *ngIf="!isPrint" class="row padding">
    <div class="leftCol" [ngClass]="{'col-12 px-0': isMobile, 'col-3 px-0': !isMobile}">
        <div class="side-menu-container" *ngIf="!isMobile">
            <fc-breadcrumb *ngIf="!isBugs"></fc-breadcrumb>
            <fc-menu-tree [isMobile]="isMobile" [isLeftNav]="true"></fc-menu-tree>
        </div>
    </div>
    <div class="cmt-2 catalog-content" appSizeSpy [ngClass]="{'col-12 px-0': isMobile, 'col-9 px-0': !isMobile}">
        <div class="loader-container" *ngIf="displayLoader">
            <div class="loader"></div>
            <div class="text">
                <p>Loading products...</p>
            </div>
        </div>
        <div *ngIf="!displayLoader && !isBugs" class="w-100">
            <div id="products-scroll-spy" *ngIf="categoriesData && categoriesData.length > 0">
                <div class="sticky-top">
                    <div id="main-header" class="row" *ngIf="categoriesData.length > 0">
                        <div class="col-12 px-0 pt-2">
                            <fc-header [categoryName]="currentCategoryName" [displayDivider]="false"
                                *ngIf="currentCategoryChildName === null && currentCategoryChildChildName === null">
                            </fc-header>
                            <fc-header [categoryName]="currentCategoryName"
                                [categoryChildName]="currentCategoryChildName" [displayDivider]="false"
                                *ngIf="currentCategoryChildName !== null && currentCategoryChildChildName === null">
                            </fc-header>
                            <fc-header [categoryName]="currentCategoryName"
                                [categoryChildName]="currentCategoryChildName"
                                [categoryChildChildName]="currentCategoryChildChildName" [displayDivider]="false"
                                *ngIf="currentCategoryChildName !== null && currentCategoryChildChildName !== null">
                            </fc-header>
                        </div>
                    </div>
                </div>
                <div class="categoryContainer" *ngFor="let categoryData of categoriesData; let i = index">
                    <div class="w-100 childs" [id]="'product-section-' + i"
                        [attr.data-category-name]="categoryData['name']" attr.data-category-child-name="null"
                        attr.data-category-child-child-name="null">
                        <div class="row">
                            <div class="col-12 px-0 pt-2" *ngIf="categoryData['products'].length > 0">
                                <fc-header [categoryName]="categoryData['name']" [displayDivider]="true">
                                </fc-header>
                            </div>
                            <div class="col-12 px-0" *ngIf="categoryData['products'].length > 0">
                                <div class="row">
                                    <div [ngClass]="{
                                            'cards col-sm-12 col-md-12 col-lg-6 col-xl-4 pb-2': isCardLayout,
                                            'cards list col-sm-12': !isCardLayout
                                        }" *ngFor="let product of categoryData['products']; let ii = index;">
                                        <div class="row">
                                            <fc-card class="fc-card" [product]="product"
                                                [categoryId]="categoryData['id']" [isPrint]="isPrint"
                                                (downloadCardClicked)="onDownloadCardClicked($event)"
                                                (thumbnailClicked)="onThumbnailClicked($event)">
                                            </fc-card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngFor="let categoryChild of categoryData['children']; let j = index"
                            class="w-100 childs-childs" [id]="'product-section-' + i + '-child-' + j"
                            [attr.data-category-name]="categoryData['name']"
                            [attr.data-category-child-name]="categoryChild['name']">
                            <div class="row" *ngIf="categoryChild['products'].length > 0">
                                <div class="col-12 px-0 pt-2">
                                    <fc-header [categoryName]="categoryData['name']"
                                        [categoryChildName]="categoryChild['name']" [displayDivider]="true">
                                    </fc-header>
                                </div>
                                <div class="col-12 px-0">
                                    <div class="row">
                                        <div [ngClass]="{
                                                'cards col-sm-12 col-md-12 col-lg-6 col-xl-4 pb-2': isCardLayout,
                                                'cards list col-sm-12': !isCardLayout
                                            }" *ngFor="let productChild of categoryChild['products']; let jj = index;">
                                            <div class="row">
                                                <fc-card class="fc-card" [product]="productChild"
                                                    [categoryId]="categoryChild['id']" [isPrint]="isPrint"
                                                    (downloadCardClicked)="onDownloadCardClicked($event)"
                                                    (thumbnailClicked)="onThumbnailClicked($event)">
                                                </fc-card>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngFor="let categoryChildChild of categoryChild['children']; let k = index"
                                class="w-100 childs-childs-childs"
                                [id]="'product-section-' + i + '-child-' + j + '-child-' + k"
                                [attr.data-category-name]="categoryData['name']"
                                [attr.data-category-child-name]="categoryChild['name']"
                                [attr.data-category-child-child-name]="categoryChildChild['name']">
                                <div class="row" *ngIf="categoryChildChild['products'].length > 0">
                                    <div class="col-12 px-0 pt-2">
                                        <fc-header [categoryName]="categoryData['name']"
                                            [categoryChildName]="categoryChild['name']"
                                            [categoryChildChildName]="categoryChildChild['name']"
                                            [displayDivider]="true">
                                        </fc-header>
                                    </div>
                                    <div class="col-12 px-0">
                                        <div class="row">
                                            <div [ngClass]="{
                                                    'cards col-sm-12 col-md-12 col-lg-6 col-xl-4 pb-2': isCardLayout,
                                                    'cards list col-sm-12': !isCardLayout
                                                }"
                                                *ngFor="let productChildChild of categoryChildChild['products']; let kk = index;">
                                                <div class="row">
                                                    <fc-card class="fc-card" [product]="productChildChild"
                                                        [categoryId]="categoryChildChild['id']" [isPrint]="isPrint"
                                                        (downloadCardClicked)="onDownloadCardClicked($event)"
                                                        (thumbnailClicked)="onThumbnailClicked($event)">
                                                    </fc-card>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!displayLoader && isBugs && categoryProducts && categoryProducts.length > 0" class="w-100 h-100">
            <div class="row">
                <div class="col-12 px-0">
                    <div appSizeSpy class="row categoryContainer">
                        <div [ngClass]="{
                                'cards col-sm-12 col-md-12 col-lg-6 col-xl-4 pb-2': isCardLayout,
                                'cards list col-sm-12': !isCardLayout
                            }" *ngFor="let categoryProduct of categoryProducts">
                            <fc-card class="fc-card" [product]="categoryProduct" [isPrint]="isPrint"
                                (downloadCardClicked)="onDownloadCardClicked($event)"
                                (thumbnailClicked)="onThumbnailClicked($event)"></fc-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!displayLoader && isBugs && categoryProducts && categoryProducts.length === 0" class="w-100 h-100">
            <div class="row">
                <div class="col-12">
                    <div class="no-results">
                        <span>No Results Found</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <fc-download [product]="downloadProduct" [categoryId]="downloadCategoryId"
        [sellSheetStatusData]="sellSheetStatusData" *ngIf="downloadProduct !== null">
    </fc-download>
</div>

<div *ngIf="isPrint" style="margin-left: 60px; margin-right: 60px;">
    <div *ngIf="isPrintFullCatalog" class="print-header">
        <div class="h-100">
            <div class="row h-100">
                <div class="col-4">
                    <div class="print-title" *ngIf="sharedService.environmentIsMCOE()">
                        MCOE Catalog
                    </div>
                    <div class="print-title" *ngIf="sharedService.environmentIsFSV()">
                        PepsiCo - Foodservice
                    </div>
                </div>
                <div class="col-4">
                    <div class="print-date">
                        {{ todayString }}
                    </div>
                </div>
                <div class="col-4">
                    <div class="print-pages">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isPrintFullCatalog" class="print-heading">
        <div>
            <fc-heading [categoryName]="'Full'"></fc-heading>
        </div>
    </div>
    <div *ngFor="let categoryData of categoriesData" [ngClass]="{'print-header': categoryData['type'] === 'header', 'print-footer': categoryData['type'] === 'footer', 'print-heading': categoryData['type'] === 'heading', 'print-header-products': categoryData['type']
        === 'headerAndProducts', 'print-products-row':
        categoryData['type'] === 'products'}">
        <div *ngIf="categoryData['type'] === 'header'" class="h-100">
            <div class="row h-100">
                <div class="col-4">
                    <div class="print-title" *ngIf="sharedService.environmentIsMCOE()">
                        MCOE Catalog
                    </div>
                    <div class="print-title" *ngIf="sharedService.environmentIsFSV()">
                        PepsiCo - Foodservice
                    </div>
                </div>
                <div class="col-4">
                    <div class="print-date">
                        {{ todayString }}
                    </div>
                </div>
                <div class="col-4">
                    <div class="print-pages">
                        Page {{ categoryData['value'] }} / {{ printNumberOfPages }}
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="categoryData['type'] === 'footer'"></div>
        <div *ngIf="categoryData['type'] === 'heading'">
            <fc-heading [categoryName]="categoryData['name']" [firstParentName]="categoryData['firstParentName']">
            </fc-heading>
        </div>
        <div *ngIf="categoryData['type'] === 'headerAndProducts'">
            <div *ngFor="let data of categoryData['data']" [ngClass]="{'product-item-header': data['type'] === 'header', 'product-item': data['type'] ===
                'product'}">
                <div *ngIf="data['type'] === 'header'">
                    <fc-header [categoryName]="data['categoryName']" [displayDivider]="true"
                        *ngIf="data['categoryChildName'] === null && data['categoryChildChildName'] === null">
                    </fc-header>
                    <fc-header [categoryName]="data['categoryName']" [categoryChildName]="data['categoryChildName']"
                        [displayDivider]="true"
                        *ngIf="data['categoryChildName'] !== null && data['categoryChildChildName'] === null">
                    </fc-header>
                    <fc-header [categoryName]="data['categoryName']" [categoryChildName]="data['categoryChildName']"
                        [categoryChildChildName]="data['categoryChildChildName']" [displayDivider]="true"
                        *ngIf="data['categoryChildName'] !== null && data['categoryChildChildName'] !== null">
                    </fc-header>
                </div>
                <div *ngIf="data['type'] === 'products'">
                    <div *ngFor=" let product of data['data']" class="product-item">
                        <div class="cards">
                            <fc-card class="fc-card" [product]="product" [isPrint]="isPrint"
                                (downloadCardClicked)="onDownloadCardClicked($event)"
                                (thumbnailClicked)="onThumbnailClicked($event)">
                            </fc-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf=" categoryData['type']==='products'">
            <div *ngFor=" let product of categoryData['data']" class="product-item">
                <div class="cards">
                    <fc-card class="fc-card" [product]="product" [isPrint]="isPrint"
                        (downloadCardClicked)="onDownloadCardClicked($event)"
                        (thumbnailClicked)="onThumbnailClicked($event)">
                    </fc-card>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="categoryProducts && categoryProducts.length > 0">
        <div class="print-header">
            <div class="h-100">
                <div class="row h-100">
                    <div class="col-4">
                        <div class="print-title" *ngIf="sharedService.environmentIsMCOE()">
                            MCOE Catalog
                        </div>
                        <div class="print-title" *ngIf="sharedService.environmentIsFSV()">
                            PepsiCo - Foodservice
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="print-date">
                            {{ todayString }}
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="print-pages">
                            Page {{ printBugHeadingPage1 }} / {{ printNumberOfPages }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="print-heading">
            <div>
                <fc-heading [categoryName]="bugReferenceName1"></fc-heading>
            </div>
        </div>
        <div *ngFor="let categoryProduct of categoryProducts"
            [ngClass]="{'print-header': categoryProduct['type'] === 'header', 'print-products-row': categoryProduct['type'] === 'products'}">
            <div *ngIf="categoryProduct['type'] === 'header'" class="h-100">
                <div class="row h-100">
                    <div class="col-4">
                        <div class="print-title" *ngIf="sharedService.environmentIsMCOE()">
                            MCOE Catalog
                        </div>
                        <div class="print-title" *ngIf="sharedService.environmentIsFSV()">
                            PepsiCo - Foodservice
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="print-date">
                            {{ todayString }}
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="print-pages">
                            Page {{ categoryProduct['value'] }} / {{ printNumberOfPages }}
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf=" categoryProduct['type']==='products'">
                <div *ngFor=" let product of categoryProduct['data']" class="product-item">
                    <div class="cards">
                        <fc-card class="fc-card" [product]="product" [isPrint]="isPrint"
                            (downloadCardClicked)="onDownloadCardClicked($event)"
                            (thumbnailClicked)="onThumbnailClicked($event)">
                        </fc-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="categoryProducts2 && categoryProducts2.length > 0">
        <div class="print-header">
            <div class="h-100">
                <div class="row h-100">
                    <div class="col-4">
                        <div class="print-title" *ngIf="sharedService.environmentIsMCOE()">
                            MCOE Catalog
                        </div>
                        <div class="print-title" *ngIf="sharedService.environmentIsFSV()">
                            PepsiCo - Foodservice
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="print-date">
                            {{ todayString }}
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="print-pages">
                            Page {{ printBugHeadingPage2 }} / {{ printNumberOfPages }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="print-heading">
            <div>
                <fc-heading [categoryName]="bugReferenceName2"></fc-heading>
            </div>
        </div>
        <div *ngFor="let categoryProduct of categoryProducts2"
            [ngClass]="{'print-header': categoryProduct['type'] === 'header', 'print-products-row': categoryProduct['type'] === 'products'}">
            <div *ngIf="categoryProduct['type'] === 'header'" class="h-100">
                <div class="row h-100">
                    <div class="col-4">
                        <div class="print-title" *ngIf="sharedService.environmentIsMCOE()">
                            MCOE Catalog
                        </div>
                        <div class="print-title" *ngIf="sharedService.environmentIsFSV()">
                            PepsiCo - Foodservice
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="print-date">
                            {{ todayString }}
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="print-pages">
                            Page {{ categoryProduct['value'] }} / {{ printNumberOfPages }}
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf=" categoryProduct['type']==='products'">
                <div *ngFor=" let product of categoryProduct['data']" class="product-item">
                    <div class="cards">
                        <fc-card class="fc-card" [product]="product" [isPrint]="isPrint"
                            (downloadCardClicked)="onDownloadCardClicked($event)"
                            (thumbnailClicked)="onThumbnailClicked($event)">
                        </fc-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="thumbnailProduct" class="modal fade" id="thumbnail-modal" tabindex="-1" role="dialog"
    aria-labelledby="thumbnailModal" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <div class="modal-header" [style.display]="display360ProductViewerInThumbnailModal ? 'none' : 'flex'">
                <h5 class="modal-title" id="thumbnailModal">
                    {{ thumbnailProduct.name }}
                </h5>
            </div>
            <div class="modal-body" style="padding: 0;">
                <div class="row" *ngIf="sharedService.environmentIsMCOE()">
                    <div class="col-12 flex-center-center"
                        *ngIf="!display360ProductViewerInThumbnailModal" style="padding: 0;">
                        <lib-ngx-image-zoom [thumbImage]="thumbnailImage" [zoomMode]="isMobile ? 'toggle' : 'click'"
                            [magnification]="thumbnailMagnification"
                            [ngClass]="{'disable': !canZoom, 'lib-ngx-image-zoom-scale': thumbnailProductHas360, 'lib-ngx-image-zoom-classic': !thumbnailProductHas360}">
                        </lib-ngx-image-zoom>
                    </div>
                    <div class="col-12 flex-center-center" *ngIf="display360ProductViewerInThumbnailModal"
                        style="padding: 0;">
                        <gc-fritolay-product-viewer [eqi]="thumbnailProduct.eqi" [productName]="thumbnailProduct.name"
                            [productWidth]="thumbnailProduct.width" [productHeight]="thumbnailProduct.height"
                            [productDepth]="thumbnailProduct.depth" [showCloseButton]="false">
                        </gc-fritolay-product-viewer>
                    </div>
                </div>
                <div class="row" *ngIf="sharedService.environmentIsFSV()">
                    <div class="col-12 flex-center-center" style="padding: 0;">
                        <img [src]="thumbnailImage" class="card-img-top">
                    </div>
                </div>
            </div>
            <div class="modal-footer modal-footer-custom"
                [ngClass]="{'with-product-modal-actions': sharedService.environmentIsMCOE()}">
                <fc-product-modal-actions [product]="thumbnailProduct"
                    (button360ClickedEmitter)="button360ClickedEmitter()" *ngIf="sharedService.environmentIsMCOE()">
                </fc-product-modal-actions>
                <button type="button" class="btn btn-1" (click)="closeThumbnailModal()">Close</button>
            </div>
        </div>
    </div>
</div>