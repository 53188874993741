<div class="row">
    <div class="col-12">
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="dashboard-main-data-tab" data-toggle="tab" href="#dashboard-main-data"
                    role="tab" aria-controls="dashboard-main-data" aria-selected="true">Data uploads</a>
            </li>
        </ul>
        <div class="tab-content">
            <div class="tab-pane fade show active" id="dashboard-main-data" role="tabpanel"
                aria-labelledby="dashboard-main-data-tab">
                <div class="mt-3">
                    <table id="dashboard-main-data-table" class="table table-bordered table-hover table-striped">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col" style="width: 75%;">Main data</th>
                                <th scope="col" style="width: 25%;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Excel file</th>
                                <td>
                                    <button class="btn btn-3" (click)="excelFileClicked()">
                                        Choose new file
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Assets zip</th>
                                <td>
                                    <button class="btn btn-3" (click)="assetsZipClicked()">
                                        Choose new file
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mt-3" *ngIf="sharedService.environmentIsMCOE()">
                    <table id="dashboard-main-data-table" class="table table-bordered table-hover table-striped">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col" style="width: 75%;">National Sales Meeting 2021</th>
                                <th scope="col" style="width: 25%;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Upload a product images zip (for 360 viewer)</th>
                                <td>
                                    <button class="btn btn-3" (click)="productImagesClicked()">
                                        Choose new file
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Upload a product video</th>
                                <td>
                                    <button class="btn btn-3" (click)="productVideoClicked()">
                                        Choose new file
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>