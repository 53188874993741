<div class="row">
    <div class="col-12">
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="dashboard-sellsheet-tab" data-toggle="tab" href="#dashboard-sellsheet"
                    role="tab" aria-controls="dashboard-sellsheet" aria-selected="true">
                    Sell Sheets Status
                </a>
            </li>
        </ul>
        <div class="tab-content">
            <div class="tab-pane fade show active" id="dashboard-sellsheet" role="tabpanel"
                aria-labelledby="dashboard-sellsheet-tab">
                <div class="mt-3">
                    This page is refresh every 30 seconds
                </div>
                <div class="mt-3">
                    <table id="dashboard-sellsheet-table" class="table table-bordered table-striped">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col" style="width: 20%;">Name</th>
                                <th scope="col" style="width: 60%;">Status</th>
                                <th scope="col" style="width: 20%;">Generation time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let currentStatus of status">
                                <th scope="row">
                                    {{ currentStatus.name }}
                                </th>
                                <td>
                                    <span *ngIf="currentStatus.status.startsWith('http') === true">
                                        <a [href]="currentStatus.status" target="_blank">
                                            {{ currentStatus.status }}
                                        </a>
                                    </span>
                                    <span class="pending" *ngIf="currentStatus.status.startsWith('http') === false">
                                        PENDING
                                    </span>
                                </td>
                                <td>
                                    {{ currentStatus.generationTime }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>