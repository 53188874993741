import { Component, OnInit, Input } from '@angular/core';

import { Product } from '../../interfaces/product';

import { SharedService } from 'src/app/shared/services/shared.service';
import { Sellsheet } from '../../interfaces/sellsheet';
import { ProductProperties } from '../../interfaces/product-properties';

@Component({
  selector: 'fc-sellsheet-template',
  templateUrl: './sellsheet-template.component.html',
  styleUrls: ['./sellsheet-template.component.scss']
})
export class SellsheetTemplateComponent implements OnInit {
  @Input() product: Product

  sellSheetEconomics?: Object
  sellSheetDisplaySpec?: Object
  imageName = ""

  constructor(
    public sharedService: SharedService
  ) { }

  ngOnInit() {
    if (this.product && this.product.sellSheet) {
      this.sellSheetEconomics = JSON.parse(this.product.sellSheet.economics)
      this.sellSheetDisplaySpec = JSON.parse(this.product.sellSheet.displaySpec)
    }

    if (this.product) {
      if (this.product.productColors[0].fullEqi !== null) {
        this.imageName = `${this.sharedService.getEnvironmentContainer()}/images-products/thumbs/${this.product.productColors[0].fullEqi}x600.jpg`
      } else {
        this.imageName = `${this.sharedService.getEnvironmentContainer()}/images-products/thumbs/${this.product.eqi}x600.jpg`
      }
    }
  }
}
