<div class="row">
    <div class="col-12">
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="dashboard-homepage-assets-tab" data-toggle="tab"
                    href="#dashboard-homepage-assets" role="tab" aria-controls="dashboard-homepage-assets"
                    aria-selected="true">Assets</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="dashboard-homepage-headers-actions-tab" data-toggle="tab"
                    href="#dashboard-homepage-headers-actions" role="tab"
                    aria-controls="dashboard-homepage-headers-actions" aria-selected="false">Actions</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="dashboard-homepage-headers-sections-tab" data-toggle="tab"
                    href="#dashboard-homepage-headers-sections" role="tab"
                    aria-controls="dashboard-homepage-headers-sections" aria-selected="false">Headers and
                    sections</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="dashboard-homepage-badges-tab" data-toggle="tab"
                    href="#dashboard-homepage-badges" role="tab" aria-controls="dashboard-homepage-badges"
                    aria-selected="false">Badges</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="dashboard-homepage-assets" role="tabpanel"
                aria-labelledby="dashboard-homepage-assets-tab">
                <div class="mt-3">
                    <table id="dashboard-homepage-table" class="table table-bordered table-hover table-striped">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col" style="width: 75%;">Asset name</th>
                                <th scope="col" style="width: 25%;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let homepageAsset of homepageAssets">
                                <th scope="row">{{ homepageAsset.displayName }}</th>
                                <td>
                                    <button class="btn btn-3" (click)="viewAndEdit(homepageAsset)">View and
                                        edit</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="tab-pane fade" id="dashboard-homepage-headers-actions" role="tabpanel"
                aria-labelledby="dashboard-homepage-headers-actions-tab">
                <fc-dashboard-actions *ngIf="homepageSettings.length > 0" [homepageSettings]="homepageSettings"
                    (showToastEvent)="showToast($event)" (needToRefreshData)="needToRefreshData()">
                </fc-dashboard-actions>
            </div>
            <div class="tab-pane fade" id="dashboard-homepage-headers-sections" role="tabpanel"
                aria-labelledby="dashboard-homepage-headers-sections-tab">
                <fc-dashboard-headers-and-sections *ngIf="homepageSettings.length > 0"
                    [homepageSettings]="homepageSettings" (showToastEvent)="showToast($event)">
                </fc-dashboard-headers-and-sections>
            </div>
            <div class="tab-pane fade" id="dashboard-homepage-badges" role="tabpanel"
                aria-labelledby="dashboard-homepage-badges-tab">
                <fc-dashboard-badges *ngIf="homepageSettings.length > 0" [homepageSettings]="homepageSettings"
                    (showToastEvent)="showToast($event)" (needToRefreshData)="needToRefreshData()">
                </fc-dashboard-badges>
            </div>
        </div>
    </div>
</div>