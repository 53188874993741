<div class="mt-3">
    <div>
        <button class="btn btn-3" (click)="addBadge()">
            Add new badge
        </button>
    </div>
</div>
<div class="mt-3" *ngIf="badges">
    <table id="dashboard-badge-table" class="table table-bordered table-hover table-striped">
        <thead class="thead-dark">
            <tr>
                <th scope="col" style="width: 15%;">Image</th>
                <th scope="col" style="width: 65%;">Name</th>
                <th scope="col" style="width: 20%;" class="center">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let badge of badges">
                <th scope="row">
                    <div class="item-image">
                        <img [src]="sharedService.getEnvironmentContainer() + '/assets/homepageBadges/' +
                                            badge.value + '?timestamp=' + timestamp">
                    </div>
                </th>
                <td>
                    {{ badge.displayName }}
                </td>
                <td>
                    <div class="dropdown">
                        <button class="btn btn-3 dropdown-toggle" type="button"
                            [id]="'dropdownMenuButtonBadge' + badge.id" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            Actions
                        </button>
                        <div class="dropdown-menu" aria-labelledby="'dropdownMenuButtonBadge' + badge.id">
                            <a class="dropdown-item" (click)="showUpdateBadgeModal(badge)">Edit</a>
                            <a class="dropdown-item" (click)="showDeleteBadgeModal(badge)">Delete</a>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- ADD -->

<div class="modal fade" id="add-new-badge-modal" tabindex="-1" role="dialog" aria-labelledby="addNewBadgeModal"
    aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <form [formGroup]="badgeForm" (submit)="onAddNewBadgeSubmit()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Add new badge
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="form-row">
                        <div class="form-group col-12 mb-0">
                            <label>Image * (.jpg, .png)</label>
                        </div>
                        <div class="form-group col-12">
                            <div class="custom-file">
                                <input type="file" formControlName="badgeFile" class="form-control custom-file-input"
                                    id="badgeFile" accept="image/jpeg,image/png" (change)="badgeFileChange($event)"
                                    [ngClass]="{ 'is-invalid':
                                    badgeFormSubmitted &&
                                    badgeForm.controls.badgeFile.errors }">
                                <label class="custom-file-label" for="badgeFile">Choose file</label>
                                <div *ngIf="badgeFormSubmitted && badgeForm.controls.badgeFile.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="badgeForm.controls.badgeFile.errors.required">
                                        Badge image is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Name *</label>
                            <input type="text" formControlName="badgeName" class="form-control" placeholder="Name"
                                [ngClass]="{ 'is-invalid':
                                    badgeFormSubmitted &&
                                badgeForm.controls.badgeName.errors }" />
                            <div *ngIf="badgeFormSubmitted && badgeForm.controls.badgeName.errors"
                                class="invalid-feedback">
                                <div *ngIf="badgeForm.controls.badgeName.errors.required">
                                    Badge name is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary btn-new-section" (click)="closeNewBadgeModal()">
                        Close
                    </button>
                    <button class="btn btn-3 btn-new-section">
                        Add new badge
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- DELETE -->

<div *ngIf="currentBadge" class="modal fade" id="delete-badge-modal" tabindex="-1" role="dialog"
    aria-labelledby="deleteBadgeModal" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="deleteBadgeModal">
                    Delete badge
                </h5>
            </div>
            <div class="modal-body">
                Are you sure to delete this badge?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-new-section" (click)="closeDeleteBadgeModal()">
                    Close
                </button>
                <button class="btn btn-3 btn-new-section" (click)="deletebadge(currentBadge.id)">
                    Confirm
                </button>
            </div>
        </div>
    </div>
</div>

<!-- UPDATE -->

<div *ngIf="currentBadge" class="modal fade" id="update-badge-modal" tabindex="-1" role="dialog"
    aria-labelledby="updateBadgeModal" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <form [formGroup]="badgeForm" (submit)="onUpdateBadgeSubmit()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Edit badge
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="form-row">
                        <div class="form-group col-12">
                            <div class="flex-center-center">
                                <div class="item-image large">
                                    <img [src]="sharedService.getEnvironmentContainer() + '/assets/homepageBadges/' +
                                    currentBadge.value + '?timestamp=' + timestamp">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12 mb-0">
                            <label>Image (.jpg, .png)</label>
                        </div>
                        <div class="form-group col-12">
                            <div class="custom-file">
                                <input type="file" formControlName="badgeFile" class="form-control custom-file-input"
                                    id="badgeFile2" accept="image/jpeg,image/png" (change)="badgeFileChange($event)">
                                <label class="custom-file-label" for="badgeFile2">Choose file</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Name *</label>
                            <input type="text" formControlName="badgeName" class="form-control" placeholder="Name"
                                [value]="currentBadge.displayName" [ngClass]="{ 'is-invalid':
                                    badgeFormSubmitted &&
                                badgeForm.controls.badgeName.errors }" />
                            <div *ngIf="badgeFormSubmitted && badgeForm.controls.badgeName.errors"
                                class="invalid-feedback">
                                <div *ngIf="badgeForm.controls.badgeName.errors.required">
                                    Badge name is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary btn-new-section" (click)="closeUpdateBadgeModal()">
                        Close
                    </button>
                    <button class="btn btn-3 btn-new-section">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>