<div class="row">
    <div class="col-12">
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="dashboard-categories-tab" data-toggle="tab" href="#dashboard-categories"
                    role="tab" aria-controls="dashboard-categories" aria-selected="true">
                    Categories
                </a>
            </li>
        </ul>
        <div class="tab-content">
            <div class="tab-pane fade show active" id="dashboard-categories" role="tabpanel"
                aria-labelledby="dashboard-categories-tab">
                <div class="mt-3">
                    <div>
                        <button class="btn btn-3" (click)="addNewCategory()">
                            Add new category
                        </button>
                    </div>
                </div>
                <div class="mt-3" *ngIf="categoriesL1">
                    <table id="dashboard-categories-table" class="table table-bordered">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col" style="width: 10%;">Level</th>
                                <th scope="col" style="width: 70%;">Name</th>
                                <th scope="col" style="width: 20%;">Actions</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let categoryL1 of categoriesL1">
                            <tr>
                                <td>
                                    1
                                </td>
                                <td>
                                    {{ categoryL1.name }}
                                </td>
                                <td>
                                    <div class="dropdown">
                                        <button class="btn btn-3 dropdown-toggle" type="button"
                                            [id]="'dropdownMenuButton' + categoryL1.id" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                            Actions
                                        </button>
                                        <div class="dropdown-menu"
                                            aria-labelledby="'dropdownMenuButton' + categoryL1.id">
                                            <a class="dropdown-item"
                                                (click)="showUpdateCategoryModal(categoryL1)">Edit</a>
                                            <a class="dropdown-item"
                                                (click)="showDeleteCategoryModal(categoryL1)">Delete</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="categoryL1.children.length > 0">
                                <td scope="row" colspan="3">
                                    <table id="dashboard-categories-table-l2" class="table table-bordered subtable">
                                        <thead class="thead-dark">
                                            <tr>
                                                <th scope="col" style="width: 10%;">Level</th>
                                                <th scope="col" style="width: 70%;">Name</th>
                                                <th scope="col" style="width: 20%;">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let categoryL2 of categoryL1.children">
                                            <tr>
                                                <td>
                                                    2
                                                </td>
                                                <td>
                                                    {{ categoryL2.name }}
                                                </td>
                                                <td>
                                                    <div class="dropdown">
                                                        <button class="btn btn-3 dropdown-toggle" type="button"
                                                            [id]="'dropdownMenuButton' + categoryL2.id"
                                                            data-toggle="dropdown" aria-haspopup="true"
                                                            aria-expanded="false">
                                                            Actions
                                                        </button>
                                                        <div class="dropdown-menu"
                                                            aria-labelledby="'dropdownMenuButton' + categoryL2.id">
                                                            <a class="dropdown-item"
                                                                (click)="showUpdateCategoryModal(categoryL2)">Edit</a>
                                                            <a class="dropdown-item"
                                                                (click)="showDeleteCategoryModal(categoryL2)">Delete</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngIf="categoryL2.children.length > 0">
                                                <td scope="row" colspan="3">
                                                    <table id="dashboard-categories-table-l3"
                                                        class="table table-bordered subtable">
                                                        <thead class="thead-dark">
                                                            <tr>
                                                                <th scope="col" style="width: 10%;">Level</th>
                                                                <th scope="col" style="width: 70%;">Name</th>
                                                                <th scope="col" style="width: 20%;">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let categoryL3 of categoryL2.children">
                                                                <td>
                                                                    3
                                                                </td>
                                                                <td>
                                                                    {{ categoryL3.name }}
                                                                </td>
                                                                <td>
                                                                    <div class="dropdown">
                                                                        <button class="btn btn-3 dropdown-toggle"
                                                                            type="button"
                                                                            [id]="'dropdownMenuButton' + categoryL3.id"
                                                                            data-toggle="dropdown" aria-haspopup="true"
                                                                            aria-expanded="false">
                                                                            Actions
                                                                        </button>
                                                                        <div class="dropdown-menu"
                                                                            aria-labelledby="'dropdownMenuButton' + categoryL3.id">
                                                                            <a class="dropdown-item"
                                                                                (click)="showUpdateCategoryModal(categoryL3)">Update</a>
                                                                            <a class="dropdown-item"
                                                                                (click)="showDeleteCategoryModal(categoryL3)">Delete</a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="add-new-category-modal" tabindex="-1" role="dialog" aria-labelledby="addNewCategoryModal"
    aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <form [formGroup]="categoryForm" (submit)="onAddNewCategorySubmit()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Add new category
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Category name *</label>
                            <input type="text" formControlName="categoryName" class="form-control"
                                placeholder="Category name" [ngClass]="{ 'is-invalid': categoryFormSubmitted &&
                            categoryForm.controls.categoryName.errors }" />
                            <div *ngIf="categoryFormSubmitted && categoryForm.controls.categoryName.errors"
                                class="invalid-feedback">
                                <div *ngIf="categoryForm.controls.categoryName.errors.required">
                                    Category name is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="isATopLevelCategorySwitch"
                                    (change)="isATopLevelCategorySwitchChanged($event)">
                                <label class="custom-control-label" for="isATopLevelCategorySwitch">
                                    Is a top level category *
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Parent category *</label>
                            <select class="form-control category-level" formControlName="categoryL1" [ngClass]="{ 'is-invalid': categoryFormSubmitted &&
                            categoryForm.controls.categoryL1.errors }" (change)="categoriesL1Changed($event)">
                                <option selected value="null">Choose Level 1 category</option>
                                <option *ngFor="let categoryL1 of categoriesL1" [value]="categoryL1.id">
                                    {{ categoryL1.id }} - {{ categoryL1.name }}
                                </option>
                            </select>
                            <div *ngIf="categoryFormSubmitted && categoryForm.controls.categoryL1.errors"
                                class="invalid-feedback">
                                <div *ngIf="categoryForm.controls.categoryL1.errors">
                                    Parent category is required
                                </div>
                            </div>
                            <div class="mt-2">
                                <select class="form-control category-level" formControlName="categoryL2">
                                    <option value="null">Choose Level 2 category</option>
                                    <option *ngFor="let categoryL2 of categoriesL2" [value]="categoryL2.id">
                                        {{ categoryL2.id }} - {{ categoryL2.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary btn-new-section"
                        (click)="closeAddNewCategoryModal()">
                        Close
                    </button>
                    <button class="btn btn-3 btn-new-section">
                        Add new category
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div *ngIf="currentCategory" class="modal fade" id="delete-category-modal" tabindex="-1" role="dialog"
    aria-labelledby="deleteCategoryModal" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="deleteCategoryModal">
                    Delete category
                </h5>
            </div>
            <div class="modal-body">
                Are you sure to delete {{ currentCategory.name }} category?
                <br><br>
                <b>BE CAREFUL! All subcategories and all linked products will be also deleted!</b>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-new-section" (click)="closeDeleteCategoryModal()">
                    Close
                </button>
                <button class="btn btn-3 btn-new-section" (click)="deleteCategory(currentCategory.id)">
                    Confirm
                </button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="currentCategory" class="modal fade" id="update-category-modal" tabindex="-1" role="dialog"
    aria-labelledby="updateCategoryModal" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <form [formGroup]="categoryForm" (submit)="onUpdateCategorySubmit()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Update category
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Category name *</label>
                            <input type="text" formControlName="categoryName" class="form-control"
                                placeholder="Category name" [value]="currentCategory.name" [ngClass]="{ 'is-invalid':
                                categoryFormSubmitted &&
                            categoryForm.controls.categoryName.errors }" />
                            <div *ngIf="categoryFormSubmitted && categoryForm.controls.categoryName.errors"
                                class="invalid-feedback">
                                <div *ngIf="categoryForm.controls.categoryName.errors.required">
                                    Category name is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="isATopLevelCategorySwitch2"
                                    (change)="isATopLevelCategorySwitchChanged($event)">
                                <label class="custom-control-label" for="isATopLevelCategorySwitch2">
                                    Is a top level category *
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Parent category *</label>
                            <select id="dada" class="form-control category-level" formControlName="categoryL1"
                                [ngClass]="{ 'is-invalid': categoryFormSubmitted &&
                            categoryForm.controls.categoryL1.errors }" (change)="categoriesL1Changed($event)">
                                <option selected value="null">Choose Level 1 category</option>
                                <option *ngFor="let categoryL1 of categoriesL1" [value]="categoryL1.id"
                                    [disabled]="currentCategory.id === categoryL1.id">
                                    {{ categoryL1.id }} - {{ categoryL1.name }}
                                </option>
                            </select>
                            <div *ngIf="categoryFormSubmitted && categoryForm.controls.categoryL1.errors"
                                class="invalid-feedback">
                                <div *ngIf="categoryForm.controls.categoryL1.errors">
                                    Parent category is required
                                </div>
                            </div>
                            <div class="mt-2">
                                <select class="form-control category-level" formControlName="categoryL2">
                                    <option value="null">Choose Level 2 category</option>
                                    <option *ngFor="let categoryL2 of categoriesL2" [value]="categoryL2.id"
                                        [disabled]="currentCategory.id === categoryL2.id">
                                        {{ categoryL2.id }} - {{ categoryL2.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary btn-new-section"
                        (click)="closeUpdateCategoryModal()">
                        Close
                    </button>
                    <button class="btn btn-3 btn-new-section">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>