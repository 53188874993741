import { Component, OnInit } from '@angular/core';

import { AuthServiceAdmin } from "../auth.service";
import { SharedService } from '../../shared/services/shared.service';

import { Subject } from 'rxjs'

declare var $: any;

@Component({
  selector: 'fc-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  toastMessage: string = ""
  modalContent: any = null
  closeModalSubject: Subject<any> = new Subject<any>();

  constructor(
    private authService: AuthServiceAdmin,
    public sharedService: SharedService
  ) { }

  ngOnInit() { }

  logout(): void {
    this.authService.logout()
  }

  showToast(message: string): void {
    this.toastMessage = message

    $("#adminToast").toast('show');
  }

  showUploadModal(event: any) {
    this.modalContent = event

    setTimeout(() => {
      $("#black-overlay").addClass('visible')
      $('#upload-modal').modal('show');
    })
  }

  closeModalEvent() {
    this.closeModalSubject.next();
  }
}
