import { Injectable } from '@angular/core'

import { Setting } from '../interfaces/setting'

import { Query } from 'apollo-angular';
import gql from 'graphql-tag';

export interface Settings {
    settings: Setting[];
}

@Injectable({
    providedIn: 'root'
})
export class SettingsService extends Query<Settings> {
    document = gql`
    query {
      settings {
        id, displayName, key, value
      }
    }`;
}
