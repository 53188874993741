<div class="search">
    <div class="w-100" id="navbarSupportedContent">
        <div class="form-inline my-2 my-lg-0 w-100">
            <input id="search-input" class="form-control" type="search" placeholder="What can we help you find today?"
                aria-label="Search" (keyup)="searchTerm$.next($event.target.value)">
            <button class="btn btn-1 my-2 my-sm-0 search-button"
                (click)="this.clientFunctions[sharedService.getEnvironmentId()].searchButtonClicked()">
                <img src="assets/images/navbar/shape.svg" id="shape" class="search-button-image">
                <div class="spinner-grow spinner-grow-sm search-spinner" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </button>
        </div>
        <div class="search-results-list" *ngIf="searchResults">
            <div class="row" *ngIf="searchResults.length > 0">
                <div class="col-12 my-1" *ngFor="let searchResult of searchResults">
                    <a (click)="searchResultClicked(searchResult)" class="ga-clic-searchbar"
                        [attr.data-titlega]="searchResult.name">
                        <span class="tip" *ngIf="searchResult.type !== 'Product'">{{ searchResult.type }}:</span>
                        {{ searchResult.name }}</a>
                </div>
            </div>
            <div class="row" *ngIf="searchResults.length === 0">
                <div class="col-12 my-1">
                    <span>No Results Found</span>
                </div>
            </div>
        </div>
    </div>
</div>