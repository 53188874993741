<nav class="navbar navbar-expand-lg navbar-light navbar-custom" [ngClass]="{'fsv': sharedService.environmentIsFSV()}">
    <div class="container">
        <div class="row w-100">
            <div class="col-6 col-lg-2 logo" *ngIf="!showMobileSearchBar"
                [ngClass]="{'col-6 col-lg-2': !isMobile, 'col-6': isMobile}">
                <a class="navbar-brand" (click)="goToHome()"
                    [ngClass]="{'auth': !isFsvSecureAccess, 'fsv': sharedService.environmentIsFSV()}">
                    <div *ngIf="sharedService.environmentIsMCOE()" [ngClass]="{'mobile': isMobile === true}">
                        <img src="assets/images/navbar/group-173@3x.png" />
                        <span [ngClass]="{'mobile': isMobile === true}">Merchandising Center of Excellence</span>
                    </div>
                    <div *ngIf="sharedService.environmentIsFSV()" class="fsv">
                        <img src="assets/images/navbar/pepsico-logo@3x.png" class="fsv" />
                    </div>
                </a>
            </div>
            <div class="col-6" *ngIf="!isMobile && isFsvSecureAccess">
                <fc-search></fc-search>
            </div>
            <div class="col-4 right-side" *ngIf="!isMobile && isFsvSecureAccess"
                [ngClass]="{'fsv': sharedService.environmentIsFSV()}">
                <div style="display: flex; justify-content: flex-start; align-items: center; margin-right: 3px; flex: 1;"
                    *ngIf="sharedService.environmentIsMCOE()">
                    <a class="btn-4" style="cursor: pointer;" (click)="switchDisplayStyle()">
                        <img *ngIf="isCardLayout" src="assets/images/products/CardViewOn.png" class="ga-clic-homepage"
                            data-titlega="Header - Display style - List view" style="height: 45px;" />
                        <img *ngIf="!isCardLayout" src="assets/images/products/ListViewOn.png" class="ga-clic-homepage"
                            data-titlega="Header - Display style - Card view" style="height: 45px;" />
                    </a>
                </div>
                <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;"
                    *ngIf="sharedService.environmentIsMCOE()">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link ga-clic-homepage"
                                (click)="this.clientFunctions[sharedService.getEnvironmentId()].faqClicked('faq')"
                                data-titlega="Header - FAQ">FAQ</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link ga-clic-homepage"
                                (click)="this.clientFunctions[sharedService.getEnvironmentId()].contactClicked()"
                                data-titlega="Header - Contact">Contact</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link ga-clic-homepage"
                                (click)="this.clientFunctions[sharedService.getEnvironmentId()].displayHelpModalClicked()"
                                data-titlega="Header - Help">
                                Help
                            </a>
                        </li>
                    </ul>
                    <div id="buttons-container-temp">
                        <button class="btn btn-2 my-2 my-sm-0 ga-clic-homepage button-nav-temp-1"
                            (click)="this.clientFunctions[sharedService.getEnvironmentId()].inventoryClicked()"
                            data-titlega="Header - Inventory">
                            Inventory &<br>Sell
                            Sheets
                        </button>
                        <button class="btn btn-1 my-2 my-sm-0 ga-clic-homepage button-nav-temp-2"
                            [ngClass]="{'fsv': sharedService.environmentIsFSV()}" data-toggle="modal"
                            (click)="this.clientFunctions[sharedService.getEnvironmentId()].displayPrintModalClicked()"
                            data-titlega="Header - Print PDF">
                            Download & Print PDF
                        </button>
                    </div>
                </div>
                <ul class="navbar-nav" *ngIf="sharedService.environmentIsFSV()">
                    <li class="nav-item">
                        <a class="nav-link ga-clic-homepage"
                            (click)="this.clientFunctions[sharedService.getEnvironmentId()].faqClicked('faq')"
                            data-titlega="Header - FAQ">FAQ</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link ga-clic-homepage"
                            (click)="this.clientFunctions[sharedService.getEnvironmentId()].contactClicked()"
                            data-titlega="Header - Contact">Contact</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link ga-clic-homepage"
                            (click)="this.clientFunctions[sharedService.getEnvironmentId()].displayHelpModalClicked()"
                            data-titlega="Header - Help">
                            Help
                        </a>
                    </li>
                </ul>
                <div style="display: flex; justify-content: center; align-items: center; flex-direction: row;"
                    *ngIf="sharedService.environmentIsFSV()">
                    <button class="btn btn-2 my-2 my-sm-0 mr-3 ga-clic-homepage"
                        [ngClass]="{'fsv': sharedService.environmentIsFSV()}" data-toggle="modal"
                        (click)="this.clientFunctions[sharedService.getEnvironmentId()].inventoryClicked()"
                        data-titlega="Header - Inventory" style="line-height: unset; width: 8.5rem;">
                        Reports, Forms<br>& Sell Sheets
                    </button>
                    <button class="btn btn-1 my-2 my-sm-0 ga-clic-homepage"
                        [ngClass]="{'fsv': sharedService.environmentIsFSV()}" data-toggle="modal"
                        (click)="this.clientFunctions[sharedService.getEnvironmentId()].displayPrintModalClicked()"
                        data-titlega="Header - Print PDF">
                        Download & Print PDF
                    </button>
                </div>
            </div>
            <div *ngIf="isMobile && isFsvSecureAccess"
                [ngClass]="{'col-6 p-0': !showMobileSearchBar, 'col-10': showMobileSearchBar}">
                <div class="right-side" *ngIf="!showMobileSearchBar" [ngClass]="{'is-mobile': isMobile}">
                    <button class="navbar-toggler menu-toggle" type="button" [ngClass]="{'is-mobile': isMobile}">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="mobile-actions">
                        <button class="btn ga-clic-homepage" data-toggle="modal"
                            (click)="this.clientFunctions[sharedService.getEnvironmentId()].displayPrintModalClicked()"
                            data-titlega="Header - Print PDF">
                            <i class="material-icons ga-clic-homepage" data-titlega="Header - Print PDF">print</i>
                        </button>
                    </div>
                    <div class="mobile-actions">
                        <a (click)="toggleShowMobileSearchBar()">
                            <i class="material-icons">search</i>
                        </a>
                    </div>
                </div>
                <div class="w-100 h-100" *ngIf="showMobileSearchBar">
                    <fc-search></fc-search>
                </div>
            </div>
            <div *ngIf="isMobile && showMobileSearchBar" class="col-2">
                <div class="cancel">
                    <button class="btn" (click)="cancelSearchClicked()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</nav>

<!-- Modal -->
<div class="modal fade" id="print-modal" tabindex="-1" role="dialog" aria-labelledby="printModal" aria-hidden="true"
    data-backdrop="false" *ngIf="isFsvSecureAccess">
    <div class="modal-dialog modal-dialog-centered" role="document"
        [ngClass]="{'fsv': sharedService.environmentIsFSV()}">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="printModal">Print</h5>
                <div class="print-spinner spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="modal-header">
                <p class="description mb-0">A PDF of your selected catalog sections will automatically be generated and
                    downloaded by pressing the "Print" button.
                    This may take 1 - 2 minutes depending on the amount of items selected.
                    If you are using a pop-up blocker, please disable it and try to print again.</p>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-6" *ngIf="sharedService.environmentIsMCOE()">
                        <div class="form-check py-1 custom-control custom-checkbox">
                            <input
                                class="form-check-input custom-control-input print-checkbox bugs-categories ga-clic-print-checkbox"
                                type="checkbox" value="" id="special-offers" (click)="toggleCheckbox('special-offers')"
                                data-titlega="Special Offers">
                            <label class="form-check-label custom-control-label" for="special-offers">
                                Special Offers
                            </label>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-check py-1 custom-control custom-checkbox">
                            <input
                                class="form-check-input custom-control-input print-checkbox special-categories ga-clic-print-checkbox"
                                type="checkbox" value="" id="full-catalog" (click)="toggleCheckbox('full-catalog')"
                                data-titlega="Full Catalog">
                            <label class="form-check-label custom-control-label" for="full-catalog">
                                Full Catalog
                            </label>
                        </div>
                    </div>
                    <div class="row" *ngIf="sharedService.environmentIsMCOE()">
                        <div class="col-6" *ngFor="let categoryL1 of categoriesL1">
                            <div class="form-check py-1 custom-control custom-checkbox">
                                <input
                                    class="form-check-input custom-control-input print-checkbox l1-categories ga-clic-print-checkbox"
                                    type="checkbox" value="" [id]="categoryL1.id"
                                    (click)="toggleCheckbox(categoryL1.id)" [attr.data-titlega]="categoryL1.name">
                                <label class="form-check-label custom-control-label" [for]="categoryL1.id">
                                    {{ categoryL1.name }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-6" *ngIf="sharedService.environmentIsFSV()">
                    </div>
                    <div class="col-6" *ngIf="sharedService.environmentIsFSV()">
                        <div class="form-check py-1 custom-control custom-checkbox">
                            <input
                                class="form-check-input custom-control-input print-checkbox bugs-categories ga-clic-print-checkbox"
                                type="checkbox" value="" id="header-cards" (click)="toggleCheckbox('header-cards')"
                                data-titlega="Header Cards">
                            <label class="form-check-label custom-control-label" for="header-cards">
                                Header Cards
                            </label>
                        </div>
                    </div>
                    <div class="col-6" *ngIf="sharedService.environmentIsFSV()">
                    </div>
                    <div class="col-6" *ngIf="sharedService.environmentIsFSV()">
                        <div class="form-check py-1 custom-control custom-checkbox">
                            <input
                                class="form-check-input custom-control-input print-checkbox bugs-categories ga-clic-print-checkbox"
                                type="checkbox" value="" id="accessories" (click)="toggleCheckbox('accessories')"
                                data-titlega="Accessories">
                            <label class="form-check-label custom-control-label" for="accessories">
                                Accessories
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row" *ngFor="let selectData of allSelectsData">
                    <div class="col-5">
                        <div class="form-check py-1 custom-control custom-checkbox">
                            <input
                                class="form-check-input custom-control-input print-checkbox l1-categories ga-clic-print-checkbox"
                                type="checkbox" value="" [id]="selectData.id" (click)="toggleCheckbox(selectData.id)"
                                [attr.data-titlega]="selectData.name">
                            <label class="form-check-label custom-control-label" [for]="selectData.id">
                                {{ selectData.name }}
                            </label>
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="py-1">
                            <ng-select [id]="'select-' + selectData.id" [loading]="selectData.isLoading"
                                dropdownPosition="top" (open)="onSelectOpen(selectData)" [items]="selectData.selectData"
                                bindLabel="name" bindValue="id" groupBy="level" [multiple]="true" [hideSelected]="false"
                                [closeOnSelect]="false" [selectableGroup]="true" [(ngModel)]="selectData.selectedData"
                                [placeholder]="selectData.placeholder" [searchable]="false"
                                [selectableGroupAsModel]="true" [disabled]="selectData.isDisable"
                                [groupValue]="groupValueFn">
                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <div class="ng-value">
                                        <span class="ng-value-label" *ngIf="items.length === 1">1 category
                                            selected</span>
                                        <span class="ng-value-label" *ngIf="items.length > 1">{{ items.length }}
                                            categories selected</span>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" id="print-cancel" class="btn" (click)="cancelExportToPDF()">Cancel</button>
                <button type="button" id="print-print" class="btn btn-1 ga-clic-p-print" (click)="exportToPDF()"
                    data-titlega="Print PDF">Print
                    PDF</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="help-modal" tabindex="-1" role="dialog" aria-labelledby="helpModal" aria-hidden="true"
    data-backdrop="false" *ngIf="isFsvSecureAccess">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="helpModal">Help</h5>
            </div>
            <div class="modal-header">
                <p class="description mb-0" *ngIf="sharedService.environmentIsMCOE()">
                    Please send any comments or questions relating to MCOEcatalog.com to the following SPA Mailbox: <a
                        href="mailto:FLNAMCOECatalog@pepsico.com">FLNAMCOECatalog@pepsico.com</a>.
                    <br><br>
                    *Please note that the standard Region approved process must be used to place all display orders.
                </p>
                <p class="description mb-0" *ngIf="sharedService.environmentIsFSV()">
                    Please send any comments or questions relating to fsvcatalog.com to the following SPA Mailbox: <a
                        href="mailto:PFSCatalog@pepsico.com">PFSCatalog@pepsico.com</a>
                    <br><br>
                    *Please note that the standard Region approved process must be used to place all display orders.
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" id="help-close" class="btn btn-1" (click)="closeHelpModal()">Close</button>
            </div>
        </div>
    </div>
</div>