import { ISiteSettings } from '../models/ISiteSettings';

export const SET_SITE_SETTINGS_BUGS = 'SET_SITE_SETTINGS_BUGS';
export const SET_SITE_SETTINGS_DISPLAY_STYLE = 'SET_SITE_SETTINGS_DISPLAY_STYLE';

export function setSiteSettingsReducer(state: ISiteSettings, action) {
    switch (action.type) {
        case SET_SITE_SETTINGS_BUGS:
            return {
                ...state,
                isBugs: action.payload.isBugs,
            };
        case SET_SITE_SETTINGS_DISPLAY_STYLE:
            return {
                ...state,
                displayStyle: action.payload.displayStyle,
            };
        default:
            return state;
    }
}
