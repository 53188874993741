import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import { HomepageSectionItem } from '../interfaces/homepage-section-item'

@Injectable({
  providedIn: 'root'
})
export class HomepageSectionItemCUDService {
  constructor(private apollo: Apollo) { }

  addSectionItem(item: HomepageSectionItem) {
    const createSectionItem = gql`
      mutation createSectionItem($item: AddFrontpageSectionItemInput!) {
          createSectionItem(item: $item) {
              id
          }
      }`;

    return this.apollo.mutate({
      mutation: createSectionItem,
      variables: {
        item: item
      }
    })
  }

  removeSectionItem(item: HomepageSectionItem) {
    const deleteSectionItem = gql`
      mutation deleteSectionItem($item: UpdateFrontpageSectionItemInput!) {
          deleteSectionItem(item: $item)
      }`;

    return this.apollo.mutate({
      mutation: deleteSectionItem,
      variables: {
        item: item
      }
    })
  }

  editSectionItem(item: HomepageSectionItem) {
    const updateSectionItem = gql`
      mutation updateSectionItem($item: UpdateFrontpageSectionItemInput!) {
          updateSectionItem(item: $item) {
              id
          }
      }`;

    return this.apollo.mutate({
      mutation: updateSectionItem,
      variables: {
        item: item
      }
    })
  }
}
