import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Product } from '../../interfaces/product';
import { ajax, AjaxResponse } from 'rxjs/ajax';

import { DevicesService } from 'src/app/core/services/devices.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { PublicService } from 'src/app/public/services/public.service';
import { combineLatest, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

declare var download: any;
declare var $: any;

@Component({
  selector: 'fc-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  @Input() product: Product;
  @Input() categoryId: number;
  @Input() sellSheetStatusData: any;

  isMobile: boolean = false
  canDisplayZipButton: boolean = false
  zipPath = ""
  zipName = ""
  showSellSheetLink = false
  sellSheetLink = null
  imageName = ""

  constructor(
    private devicesService: DevicesService,
    public sharedService: SharedService,
    public publicService: PublicService
  ) { }

  ngOnInit() {
    this.isMobile = this.devicesService.isMobile()

    this.buildImage()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showSellSheetLink = false
    this.sellSheetLink = null

    this.buildImage()

    this.buildAndCheckIfZipUrlExists()

    /*if (this.sharedService.environmentIsMCOE()) {
      if (this.product.hasSellSheet) {
        if (this.categoryId !== null) {
          this.publicService.getParentCategory(parseInt(String(this.categoryId)))
            .subscribe(parentCategoryData => {
              var parentL1Id = null

              if (parentCategoryData) {
                if (parentCategoryData.parent.parent !== null) {
                  parentL1Id = parentCategoryData.parent.parent.id
                } else if (parentCategoryData.parent !== null) {
                  parentL1Id = parentCategoryData.parent.id
                } else if (parentCategoryData !== null) {
                  parentL1Id = parentCategoryData.id
                }
              }

              if (parentL1Id !== null && this.sellSheetStatusData !== null) {
                var sellSheetfound = null
                switch (parseInt(parentL1Id)) {
                  case 1:
                    sellSheetfound = this.sellSheetStatusData['status'].find(x => String(x.name) === "Large Format")
                    break;
                  case 2:
                    sellSheetfound = this.sellSheetStatusData['status'].find(x => String(x.name) === "Small Format")
                    break;
                  case 4:
                    sellSheetfound = this.sellSheetStatusData['status'].find(x => String(x.name) === "Walmart")
                    break;
                  case 5:
                    sellSheetfound = this.sellSheetStatusData['status'].find(x => String(x.name) === "Temporary")
                    break;
                  default:
                    break;
                }

                if (sellSheetfound !== null) {
                  this.showSellSheetLink = true
                  this.sellSheetLink = sellSheetfound['status']
                }
              }
            }, error => {
              console.error('there was an error sending the query', error);
            });
        }
      }
    }*/
  }

  private buildImage(): void {
    if (this.product.productColors[0].fullEqi !== null) {
      this.imageName = `${this.sharedService.getEnvironmentContainer()}/images-products/thumbs/${this.product.productColors[0].fullEqi}x300.jpg`
    } else {
      this.imageName = `${this.sharedService.getEnvironmentContainer()}/images-products/thumbs/${this.product.eqi}x300.jpg`
    }
  }

  close() {
    $("#black-overlay").removeClass('visible')
    $('#download-modal').modal('hide')
  }

  download() {
    let endPoint = this.sharedService.getEnvironmentContainer() + "/images-products/originals/"
    var imgSrcWithoutExtension = ''
    var imgFullName = ''

    if (this.product.productColors[0].fullEqi !== null) {
      imgSrcWithoutExtension = endPoint + this.product.productColors[0].fullEqi
      imgFullName = this.product.productColors[0].fullEqi + '_' + this.product.name
    } else {
      imgSrcWithoutExtension = endPoint + this.product.eqi
      imgFullName = this.product.eqi + '_' + this.product.name
    }

    interface IImageVersion {
      response: AjaxResponse;
      type: 'png' | 'jpg';
      lastModified: Date;
    }

    const pngImage = imgSrcWithoutExtension + '.png'
    const jpgImage = imgSrcWithoutExtension + '.jpg'

    combineLatest([
      ajax(pngImage).pipe(catchError(e => of(null))),
      ajax(jpgImage).pipe(catchError(e => of(null))),
    ])
    .pipe(
      tap(([pngImageResult, jpgImageResult]) => {
        const dates: IImageVersion[] = []

        if (pngImageResult) {
          dates.push({
            response: pngImageResult,
            type: 'png',
            lastModified: new Date(pngImageResult.xhr.getResponseHeader('Last-Modified'))
          })
        }

        if (jpgImageResult) {
          dates.push({
            response: jpgImageResult,
            type: 'jpg',
            lastModified: new Date(jpgImageResult.xhr.getResponseHeader('Last-Modified'))
          })
        }

        if (dates.length > 0) {
          const mostUpToDate = dates.sort((a, b) => b.lastModified.getTime() - a.lastModified.getTime())[0];
  
          this.downloadImage(mostUpToDate.response.xhr.responseURL, imgFullName, mostUpToDate.type)
        }
      })
    )
    .subscribe()

    // if (this.urlExists(imgSrcWithoutExtension + '.png')) {
    //   this.downloadImage(imgSrcWithoutExtension + '.png', imgFullName, 'png')
    // } else if (this.urlExists(imgSrcWithoutExtension + '.PNG')) {
    //   this.downloadImage(imgSrcWithoutExtension + '.PNG', imgFullName, 'PNG')
    // } else if (this.urlExists(imgSrcWithoutExtension + '.jpg')) {
    //   this.downloadImage(imgSrcWithoutExtension + '.jpg', imgFullName, 'jpg')
    // } else if (this.urlExists(imgSrcWithoutExtension + '.JPG')) {
    //   this.downloadImage(imgSrcWithoutExtension + '.JPG', imgFullName, 'JPG')
    // }
  }

  private urlExists(url: string) {
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();

    return http.status != 404;
  }

  private downloadImage(url: string, name: string, extension: string) {
    if (this.isMobile) {
      window.open(url, '_blank');
    } else {
      var x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = 'blob';
      x.onload = (e) => {
        download(x.response, name + '.' + extension, "image/" + extension);
      }
      x.send();
    }
  }

  private downloadAsZip(url: string, name: string) {
    /*if (this.isMobile) {
      window.open(url, '_blank');
    } else {
      var x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = 'blob';
      x.onload = (e) => {
        console.log(x.response)
        download(x.response, name + '.zip', "application/zip");
      }
      x.send();
    }*/

    window.open(url, '_blank');
  }

  buildAndCheckIfZipUrlExists() {
    if (this.sharedService.environmentIsMCOE()) {
      this.canDisplayZipButton = false

      return
    }

    let endPoint = this.sharedService.getEnvironmentContainer() + "/images-products/alt/"
    var zipPath = ""
    var zipName = ""

    if (this.product.productColors[0].fullEqi !== null) {
      zipPath = endPoint + this.product.productColors[0].fullEqi
      zipName = String(this.product.productColors[0].fullEqi)
    } else {
      zipPath = endPoint + this.product.eqi
      zipName = String(this.product.eqi)
    }

    this.canDisplayZipButton = this.urlExists(zipPath + '.zip')
  }

  downloadZip() {
    let endPoint = this.sharedService.getEnvironmentContainer() + "/images-products/alt/"
    var zipPath = ""
    var zipName = ""

    if (this.product.productColors[0].fullEqi !== null) {
      zipPath = endPoint + this.product.productColors[0].fullEqi
      zipName = String(this.product.productColors[0].fullEqi)
    } else {
      zipPath = endPoint + this.product.eqi
      zipName = String(this.product.eqi)
    }

    this.downloadAsZip(zipPath + '.zip', zipName)
  }

  openSellSheet() {
    window.open(this.sellSheetLink, '_blank');
  }
}
