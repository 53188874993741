import { Injectable } from '@angular/core';

import { Product } from '../interfaces/product'

import { Query } from 'apollo-angular';
import gql from 'graphql-tag';

export interface ParentCategory {
  id: number;
  name: string;
  parent?: ParentCategory;
}

export interface Category {
  id: number;
  name: string;
  parent?: ParentCategory;
  products?: Product[];
  children?: Category[];
}

export interface ProductsByCategory {
  productsByCategory: Category;
}

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends Query<ProductsByCategory> {
  document = gql`
    query productsByCategory($categoryId: Int!) {
      productsByCategory(categoryId: $categoryId) {
        id, name, parent {
          id, name, parent{
            id, name
          }
        }, products {
          id, sortId, eqi, name, image, width, height, depth, uom, notes, hasSellSheet, productColors {
            fullEqi, color {
              id, code, name, image
            }
          }, bugs {
            id, name, imageUrl
          }, sellSheet {
            displaySpec, economics
          }, properties
    		}, children {
      		id, name, products {
          	id, sortId, eqi, name, image, width, height, depth, uom, notes, hasSellSheet, productColors {
              fullEqi, color {
                id, code, name, image
              }
          	}, bugs {
            	id, name, imageUrl
          	}, sellSheet {
              displaySpec, economics
            }, properties
    			} , children {
            id, name, products {
              id, sortId, eqi, name, image, width, height, depth, uom, notes, hasSellSheet, productColors {
                fullEqi, color {
                  id, code, name, image
                }
              }, bugs {
                id, name, imageUrl
              }, sellSheet {
                displaySpec, economics
              }, properties
            }
          }
    		}
      }
    }`;
}
