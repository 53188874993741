import { Injectable } from '@angular/core';

import { MenuCategory, MenuCategoriesService } from './menu-categories.service'

import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  constructor(private menuCategoriesService: MenuCategoriesService) { }

  getMenuCategories(): Observable<MenuCategory[]> {
    return this.menuCategoriesService.watch()
      .valueChanges
      .pipe(
        first(),
        map(result => result.data.categories)
      );
  }
}
