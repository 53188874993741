import { Injectable } from '@angular/core';

import { Setting } from '../interfaces/setting';

import { Query } from 'apollo-angular';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class SettingService extends Query<Setting> {
  document = gql`
    query settingByKey($key: String!) {
      settingByKey(key: $key) {
        id, key, value, displayName
      }
    }`;
}
