import { Injectable } from '@angular/core'
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { AuthServiceAdmin } from "./auth.service";

@Injectable()

export class AuthGuardAdmin implements CanActivate {

    constructor(private authService: AuthServiceAdmin, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.checkLogin()
    }

    checkLogin(): boolean {
        if (this.authService.userIsLoggedIn()) { return true }

        this.router.navigate(['/login'])

        return false
    }
}
