
export interface ISiteSettings {
    isBugs: boolean;
    displayStyle: DisplayStyle;
}

export enum DisplayStyle {
    Cards = 1,
    Rows,
}
