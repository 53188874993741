import { Injectable } from '@angular/core';

import { Product } from '../interfaces/product'

import { Query } from 'apollo-angular';
import gql from 'graphql-tag';

export interface Category {
  id: number;
  name: string;
  products: Product[];
  children: Category[];
}

export interface Categories {
  categories: Category[];
}

@Injectable({
  providedIn: 'root'
})
export class CategoriesService extends Query<Categories> {
  document = gql`
    query {
      categories {
        id, name, parent {
          id, name, parent { 
            id, name
          }
        }, products {
          id, sortId, eqi, name, image, width, height, depth, uom, notes, hasSellSheet, productColors { 
            fullEqi, color {
              id, code, name, image
            }
          }, bugs {
            id, name, imageUrl
          }, sellSheet {
            displaySpec, economics
          }, properties 
        }, children {
          id, name, products {
            id, sortId, eqi, name, image, width, height, depth, uom, notes, hasSellSheet, productColors { 
              fullEqi, color {
                id, code, name, image
              }
            }, bugs {
              id, name, imageUrl
            }, sellSheet {
              displaySpec, economics
            }, properties
          }, children {
            id, name, products {
              id, sortId, eqi, name, image, width, height, depth, uom, notes, hasSellSheet, productColors { 
                fullEqi, color {
                  id, code, name, image
                }
              }, bugs {
                id, name, imageUrl
              }, sellSheet {
                displaySpec, economics
              }, properties
            }
          }
        }
      }
    }`;
}
