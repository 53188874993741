import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';

import { UploadType } from '../upload-type'

@Component({
  selector: 'fc-dashboard-main-data',
  templateUrl: './dashboard-main-data.component.html',
  styleUrls: ['./dashboard-main-data.component.scss']
})
export class DashboardMainDataComponent implements OnInit {
  uploadType: UploadType
  itemDisplayName: string
  itemValue: string
  itemAcceptFormats: string
  itemFormatsDescription: string

  @Output() showUploadModalEvent = new EventEmitter<any>()

  constructor(
    public sharedService: SharedService
  ) { }

  ngOnInit() { }

  excelFileClicked() {
    const uploadModalContent = {
      uploadType: UploadType.Excel,
      itemDisplayName: "Excel file",
      itemValue: "assets/images/admin/document-file-xls.png",
      itemAcceptFormats: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
      itemFormatsDescription: ".xls,.xlsx"
    }

    this.showUploadModalEvent.emit(uploadModalContent)
  }

  assetsZipClicked() {
    const uploadModalContent = {
      uploadType: UploadType.AssetsZip,
      itemDisplayName: "Assets zip",
      itemValue: "assets/images/admin/document-file-zip.png",
      itemAcceptFormats: "application/zip",
      itemFormatsDescription: ".zip | Max size: 30Mb"
    }

    this.showUploadModalEvent.emit(uploadModalContent)
  }

  productImagesClicked(): void {
    const uploadModalContent = {
      uploadType: UploadType.ProductImages,
      itemDisplayName: "Upload a product images zip (for 360 viewer)",
      itemValue: "assets/images/admin/document-file-zip.png",
      itemAcceptFormats: "application/zip",
      itemFormatsDescription: ".zip | Max size: 30Mb | The zip must only contains .jpg product images (no subfolder), named from 01.jpg to 36.jpg!"
    }

    this.showUploadModalEvent.emit(uploadModalContent)
  }

  productVideoClicked(): void {
    const uploadModalContent = {
      uploadType: UploadType.ProductVideo,
      itemDisplayName: "Upload a product video",
      itemValue: "assets/images/admin/document-file-mp4.png",
      itemAcceptFormats: "video/mp4",
      itemFormatsDescription: ".mp4 | The video name must be the EQI of the product!"
    }

    this.showUploadModalEvent.emit(uploadModalContent)
  }
}
