<div class="jumbotron mt-0 py-3">
    <div class="container">
        <h1 class="display-4">PepsiCo Foodservice</h1>
        <p class="lead">Please enter the required password</p>
    </div>
</div>
<div class="row justify-content-center mb-3">
    <div class="col-12 col-lg-6">
        <form class="needs-validation" novalidate>
            <div class="form-group">
                <label for="password">Password *</label>
                <input type="password" class="form-control" id="password" name="password" placeholder="Enter password"
                    [(ngModel)]="password" required>
                <div class="invalid-feedback">
                    Please provide a password.
                </div>
            </div>
            <div class="form-group">
                <small class="text-danger">{{message}}</small>
            </div>
            <button type="button" class="btn btn-1 fsv" (click)="validateForm()">Validate</button>
        </form>
    </div>
</div>