<div class="landscape">
  <!--<div class="img">
        <img src="assets/test2.JPG" />
    </div>-->
  <div class="content">
    <div class="row top">
      <div class="box image">
        <div class="product">
          <img [src]=" imageName" />
        </div>
        <div class="art">
          <img src="assets/images/sellsheet/Sell_Sheet_Art-01.png" />
        </div>
        <div class="logo">
          <img
            src="assets/images/sellsheet/flcl_3clr_nom_pos_08_transparent.png"
          />
        </div>
      </div>
      <div class="box presentation">
        <div class="name-content">
          <div class="name">
            {{ product.name }}
          </div>
        </div>
        <div class="eqi-content2"></div>
        <div class="eqi-content"></div>
        <div class="eqi" *ngIf="product.productColors[0].fullEqi !== null">
          EQI {{ product.eqi }}
        </div>
        <div class="eqi" *ngIf="product.productColors[0].fullEqi === null">
          &nbsp;
        </div>
      </div>
      <div class="box economics" *ngIf="sellSheetEconomics">
        <div class="subbox top">
          <div class="title">Economics</div>
          <div class="economic">
            <div class="left text">SDV Holding Power</div>
            <div class="right text border-red">
              <div class="arrow-right"></div>
              {{ sellSheetEconomics["SDVHoldingPower"] }}
            </div>
          </div>
          <div class="economic">
            <div class="left text">Retail Sales/Turn</div>
            <div class="right text border-orange">
              <div class="arrow-right"></div>
              {{ sellSheetEconomics["retailSales"] }}
            </div>
          </div>
          <div class="economic">
            <div class="left text">Annual Turns</div>
            <div class="right text border-yellow">
              <div class="arrow-right"></div>
              {{ sellSheetEconomics["annualTurns"] }}
            </div>
          </div>
          <div class="economic">
            <div class="left text">Annual Retail Sales</div>
            <div class="right text border-blue">
              <div class="arrow-right"></div>
              {{ sellSheetEconomics["annualRetailSales"] }}
            </div>
          </div>
          <div class="economic">
            <div class="left text">Annual Retail Profit</div>
            <div class="right text border-blue2">
              <div class="arrow-right"></div>
              {{ sellSheetEconomics["annualRetailProfit"] }}
            </div>
          </div>
        </div>
        <div class="subbox bottom">
          <div class="texts-content">
            <div class="text-content title">
              <div class="text">Holding Power:</div>
            </div>
            <div class="text-content">
              <div class="text">
                {{ sellSheetEconomics["holdingPower1"] }}
              </div>
            </div>
            <div class="text-content">
              <div class="text">
                {{ sellSheetEconomics["holdingPower2"] }}
              </div>
            </div>
            <div class="text-content">
              <div class="text">
                {{ sellSheetEconomics["holdingPower3"] }}
              </div>
            </div>
            <div class="text-content">
              <div class="text">
                {{ sellSheetEconomics["holdingPower4"] }}
              </div>
            </div>
            <div class="text-content">
              <div class="text">
                {{ sellSheetEconomics["holdingPower5"] }}
              </div>
            </div>
            <div class="text-content">
              <div class="text">
                {{ sellSheetEconomics["holdingPower6"] }}
              </div>
            </div>
            <div class="text-content">
              <div class="text">
                {{ sellSheetEconomics["holdingPower7"] }}
              </div>
            </div>
            <div class="text-content">
              <div class="text">
                {{ sellSheetEconomics["holdingPower8"] }}
              </div>
            </div>
            <div class="text-content">
              <div class="text">
                {{ sellSheetEconomics["holdingPower9"] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row bottom">
      <div class="box display-specifications">
        <div class="title">Display Specifications</div>
        <div class="texts">
          <div class="texts-content">
            <div class="text label">Dimensions:</div>
            <div class="text">
              <span *ngIf="product.width !== null">{{ product.width }}</span>
              <span *ngIf="product.depth !== null"
                >&nbsp;x {{ product.depth }}</span
              >
              <span *ngIf="product.height !== null"
                >&nbsp;x {{ product.height }}</span
              >
            </div>
          </div>
          <div class="texts-content">
            <div class="text label">Unit of Measure:</div>
            <div class="text">
              {{ product.uom }}
            </div>
          </div>
          <div class="texts-content">
            <div class="text label">
              Est. Cost (Not Including Freight & Tax):
            </div>
            <div class="text" *ngIf="sellSheetDisplaySpec">
              {{ sellSheetDisplaySpec["estCost"] }}
            </div>
          </div>
          <div class="texts-content">
            <div class="text label">Est. Freight & Tax:</div>
            <div class="text" *ngIf="sellSheetDisplaySpec">
              {{ sellSheetDisplaySpec["estFreight"] }}
            </div>
          </div>
        </div>
      </div>
      <div class="box notes">
        <div class="header">
          <div class="title">Notes</div>
          <div
            class="colors title"
            *ngIf="
              product.productColors.length > 0 &&
              product.productColors[0]['color'].image !== ''
            "
          >
            Colors:
            <div
              class="color ml-2"
              *ngFor="let productColor of product.productColors"
            >
              <img
                [src]="
                  'assets/images/products/colors/' + productColor['color'].image
                "
              />
            </div>
          </div>
          <div
            class="colors title"
            *ngIf="
              product.productColors.length === 0 ||
              product.productColors[0]['color'].image === ''
            "
          >
            Colors: NA
          </div>
        </div>
        <div class="notes text" [innerHTML]="product.notes"></div>
        <div class="badges">
          <div class="badge" *ngFor="let bug of product.bugs">
            <img [src]="'assets/images/products/bugs/' + bug.imageUrl" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mentions">Internal Spec Sheet 2022 - Property of PepsiCo</div>
</div>
