<div *ngIf="popupData" class="custom-modal modal fade" [id]="popupKey" tabindex="-1" role="dialog"
  aria-labelledby="customModal" aria-hidden="true" data-backdrop="false">
  <div class="modal-dialog modal-dialog-centered modal-large" role="document">
    <div class="modal-content">
      <div class="modal-header" *ngIf="popupData.title">
        <h5 class="modal-title">{{ popupData.title }}</h5>
      </div>
      <div class="modal-body">
        <div id="custom-modal-content">
          <img [src]="sharedService.getEnvironmentContainer() + '/assets/customPopups/' +
                                    popupData.imageName + '?timestamp=' + timestamp"
            (click)="imgClicked(popupData.actionUrl)" />
        </div>
      </div>
      <div class="modal-footer" *ngIf="popupData.showCloseButton">
        <button type="button" class="btn btn-1" (click)="closeModal()">Close</button>
      </div>
    </div>
  </div>
</div>