import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthGuardAdmin } from './auth-guard.service'
import { AuthServiceAdmin } from './auth.service'

import { AdminRoutingModule } from './admin-routing.module';
import { DashboardModule } from './dashboard/dashboard.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AdminRoutingModule,
    DashboardModule
  ],
  providers: [AuthGuardAdmin, AuthServiceAdmin, AdminRoutingModule]
})
export class AdminModule { }
