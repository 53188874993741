import { Injectable } from '@angular/core';

import { Product } from '../interfaces/product'

import { Query } from 'apollo-angular';
import gql from 'graphql-tag';

export interface CategoryL1 {
  id: number;
  name: string;
  children: CategoryL1[];
}

export interface CategoriesL1 {
  categories: CategoryL1[];
}

@Injectable({
  providedIn: 'root'
})
export class CategoriesL1Service extends Query<CategoriesL1> {
  document = gql`
    query {
	    categories {id, name, children{id, name, children{id, name}}}      
    }`;
}
