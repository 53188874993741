<div class="mt-3">
    <table id="dashboard-homepage-actions-table" class="table table-bordered table-hover table-striped">
        <thead class="thead-dark">
            <tr>
                <th scope="col" style="width: 25%;">Asset</th>
                <th scope="col" style="width: 50%;">Target</th>
                <th scope="col" style="width: 25%;">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let action of actions">
                <th scope="row">{{ action.displayName }}</th>
                <td class="left">{{ action.value }}</td>
                <td>
                    <button class="btn btn-3" (click)="edit(action)">
                        Edit
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div *ngIf="currentAction" class="modal fade" id="edit-action-modal" tabindex="-1" role="dialog"
    aria-labelledby="editActionModal" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <form [formGroup]="actionForm" (submit)="onEditActionSubmit()">
                <div class="modal-header">
                    <h5 class="modal-title" id="editSectionModal">
                        Edit action
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Target *</label>
                            <input type="text" formControlName="target" class="form-control" placeholder="Target"
                                [value]="currentAction.value" [ngClass]="{ 'is-invalid':
                                actionFormSubmitted &&
                                actionForm.controls.target.errors }" />
                            <div *ngIf="actionFormSubmitted && actionForm.controls.target.errors"
                                class="invalid-feedback">
                                <div *ngIf="actionForm.controls.target.errors.required">
                                    Target is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="close()">
                        Close
                    </button>
                    <button class="btn btn-3">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>