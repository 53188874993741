<div *ngIf="isCardLayout" class="card product-card">
    <div class="img-container" (click)="showThumbnailModal()">
        <img [src]="imageName" class="card-img-top ga-clic-thumbnail" [attr.data-titlega]="titleGA">
    </div>
    <div class="row">
        <div style="padding-left: 5px; padding-right: 5px;" class="col-7 colors" *ngIf="product.productColors.length > 0 && product.productColors[0].color.image !== ''">
            <span class="title">Colors: </span>
            <div *ngFor="let productColors of product.productColors" class="bubbles ml-2" data-toggle="tooltip"
                data-placement="top" [title]="productColors.color.name">
                <img [src]="'assets/images/products/colors/' + productColors.color.image">
            </div>
        </div>
        <div class="col colors"
            *ngIf="product.productColors.length === 0 || product.productColors[0].color.image === ''">
            <span class="title">Colors: NA</span>
        </div>
        <div class="col download" *ngIf="!isPrint">
            <button type="button" class="btn btn-2 btn-outline" (click)="download()">
                Download
                <img src="assets/images/products/download.PNG" />
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col mx-2 text eqi" *ngIf="product.productColors[0].fullEqi !== null">
            {{product.eqi}}
        </div>
        <div class="col mx-2 text eqi" *ngIf="product.productColors[0].fullEqi === null">
            &nbsp;
        </div>
    </div>
    <div class="row">
        <div class="col mx-2 text name" [innerHTML]="product.name">
        </div>
    </div>
    <div class="row">
        <div class="col mx-2 text dimensions">
            <span *ngIf="product.width !== null">{{product.width}}</span>
            <span *ngIf="product.depth !== null">&nbsp;x {{product.depth}}</span>
            <span *ngIf="product.height !== null">&nbsp;x {{product.height}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col mx-2 text">
            UOM: {{product.uom}}
        </div>
    </div>
    <div class="row">
        <div class="col mx-2 text notes">
            <div [innerHTML]="product.notes">
            </div>
        </div>
    </div>
    <div class="row mx-3 pt-1 pl-2">
        <div *ngFor="let bug of product.bugs" class="col-20 badge" data-toggle="tooltip" data-placement="top"
            [title]="bug.name">
            <button class="btn p-0 ga-clic-bug" (click)="navigateToBugId(bug.id)" [attr.data-titlega]="bug.name">
                <img [src]="'assets/images/products/bugs/' + bug.imageUrl" class="ga-clic-bug"
                    [attr.data-titlega]="bug.name">
            </button>
        </div>
    </div>
</div>
<div *ngIf="!isCardLayout" class="card product-list row">


    <!---->
    <div class="col-1 img-container" (click)="showThumbnailModal()">
        <img [src]="imageName" class="card-img-top">
    </div>
    <div class="col-2 equi-name-container">
        <div class="text eqi" *ngIf="product.productColors[0].fullEqi !== null">
            {{product.eqi}}
        </div>
        <div class="text eqi" *ngIf="product.productColors[0].fullEqi === null">
            &nbsp;
        </div>

        <div class="text name" [innerHTML]="product.name">
        </div>
    </div>


    <div class="col-3 text notes">
        <div [innerHTML]="product.notes">
        </div>
    </div>


    <div class="col-2 equi-dimensions-container">
        <div class="mx-2 text dimensions">
            <span *ngIf="product.width !== null">{{product.width}}</span>
            <span *ngIf="product.depth !== null">&nbsp;x {{product.depth}}</span>
            <span *ngIf="product.height !== null">&nbsp;x {{product.height}}</span>
        </div>

        <div class="mx-2 text">
            UOM: {{product.uom}}
        </div>
    </div>

    <div class="col-1 colors" *ngIf="product.productColors.length > 0 && product.productColors[0].color.image !== ''">
        <div class="colors-container">
            <div *ngFor="let productColors of product.productColors" class="bubbles ml-2" data-toggle="tooltip"
                data-placement="top" [title]="productColors.color.name">
                <img [src]="'assets/images/products/colors/' + productColors.color.image">
            </div>
        </div>
    </div>

    <div class="col-1 colors" *ngIf="product.productColors.length === 0 || product.productColors[0].color.image === ''">
        <span class="title">Colors: NA</span>
    </div>

    <div class="col-1 bugs">
        <div *ngFor="let bug of product.bugs" class=" badge" data-toggle="tooltip" data-placement="top"
            [title]="bug.name">
            <button class="btn p-0 ga-clic-bug" (click)="navigateToBugId(bug.id)" [attr.data-titlega]="bug.name">
                <img [src]="'assets/images/products/bugs/' + bug.imageUrl" class="ga-clic-bug"
                    [attr.data-titlega]="bug.name">
            </button>
        </div>
    </div>

    <div class="col-2 download" *ngIf="!isPrint">
        <button type="button" class="btn btn-2 btn-outline" (click)="download()">
            Download
            <img src="assets/images/products/download.PNG" />
        </button>
    </div>


</div>

<div *ngIf="!isCardLayout" class="card product-list-mobile mobile row">

    <!---->
    <div class="col-3">
        <div class="img-container" (click)="showThumbnailModal()">
            <img [src]="imageName" class="card-img-top">
        </div>

        <div class="colors" *ngIf="product.productColors.length > 0 && product.productColors[0].color.image !== ''">

            <div class="colors-container">
                <div *ngFor="let productColors of product.productColors" class="bubbles ml-2" data-toggle="tooltip"
                    data-placement="top" [title]="productColors.color.name">
                    <img [src]="'assets/images/products/colors/' + productColors.color.image">
                </div>
            </div>
        </div>

        <div class="colors" *ngIf="product.productColors.length === 0 || product.productColors[0].color.image === ''">
            <span class="title">Colors: NA</span>
        </div>

    </div>


    <div class="col-3">
        <div class="equi-name-container">
            <div class="text eqi" *ngIf="product.productColors[0].fullEqi !== null">
                {{product.eqi}}
            </div>
            <div class="text eqi" *ngIf="product.productColors[0].fullEqi === null">
                &nbsp;
            </div>

            <div class="text name" [innerHTML]="product.name">
            </div>
        </div>

        <div class="bugs">
            <div *ngFor="let bug of product.bugs" class=" badge" data-toggle="tooltip" data-placement="top"
                [title]="bug.name">
                <button class="btn p-0 ga-clic-bug" (click)="navigateToBugId(bug.id)" [attr.data-titlega]="bug.name">
                    <img [src]="'assets/images/products/bugs/' + bug.imageUrl" class="ga-clic-bug"
                        [attr.data-titlega]="bug.name">
                </button>
            </div>
        </div>

    </div>

    <div class="col-6 product-details">

        <div class="text notes">
            <div [innerHTML]="product.notes">
            </div>
        </div>

        <div class="equi-dimensions-container">
            <div class="text dimensions">
                <span *ngIf="product.width !== null">{{product.width}}</span>
                <span *ngIf="product.depth !== null">&nbsp;x {{product.depth}}</span>
                <span *ngIf="product.height !== null">&nbsp;x {{product.height}}</span>
            </div>

            <div class="text">
                UOM: {{product.uom}}
            </div>
        </div>

        <div class="download" *ngIf="!isPrint">
            <button type="button" class="btn btn-2 btn-outline" (click)="download()">
                Download
                <img src="assets/images/products/download.PNG" />
            </button>
        </div>
    </div>

</div>