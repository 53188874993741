<div class="modal fade" id="upload-modal" tabindex="-1" role="dialog" aria-labelledby="uploadModal" aria-hidden="true"
    data-backdrop="false">
    <div class="modal-dialog modal-dialog-centered modal-large" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="uploadModal">
                    {{ modalContent.itemDisplayName }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="card mt-3 mb-3" style="max-width: 540px;">
                    <div class="row no-gutters">
                        <div class="col-md-4 flex-center-center">
                            <img class="file-type" [src]="modalContent.itemValue + '?timestamp=' +
                                timestamp">
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Select file to upload</h5>
                                <div>
                                    <button class="btn btn-secondary"
                                        onclick="document.getElementById('getItemFile').click()">
                                        Browse...
                                    </button>
                                    <input type='file' id="getItemFile" [accept]="modalContent.itemAcceptFormats"
                                        style="display: none" (change)="itemFileProgress($event)" />
                                    <p class="card-text mb-0">
                                        <small class="text-muted">
                                            Allowed formats: <b>{{ modalContent.itemFormatsDescription }}</b>
                                        </small>
                                    </p>
                                    <div *ngIf="itemFileName" class="item-upload">
                                        <p class="card-text mb-0">
                                            <small class="text-muted">
                                                <b>{{ itemFileName }}</b>
                                            </small>
                                        </p>
                                        <button id="btn-upload" class="btn btn-3 mt-3" (click)="uploadItem()">
                                            Upload
                                        </button>
                                    </div>
                                    <div *ngIf="!itemFileName" class="item-upload">
                                        <p class="card-text mb-0">
                                            <small class="text-muted">
                                                <b>No file selected</b>
                                            </small>
                                        </p>
                                        <button id="btn-upload" class="btn btn-3 mt-3" disabled (click)="uploadItem()">
                                            Upload
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" id="upload-modal-close" class="btn btn-secondary" (click)="closeModal()">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>