<div class="row">
  <div class="col-12">
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="dashboard-popups-tab" data-toggle="tab" href="#dashboard-popups" role="tab"
          aria-controls="dashboard-popups" aria-selected="true">Popups</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="dashboard-popups" role="tabpanel"
        aria-labelledby="dashboard-popups-tab">
        <div class="mt-3">
          <table id="dashboard-popups-table" class="table table-bordered table-hover table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col" style="width: 75%;">Popup name</th>
                <th scope="col" style="width: 25%;">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let popupSettings of popupsSettings">
                <th scope="row">{{ popupSettings.displayName }}</th>
                <td>
                  <button class="btn btn-3" (click)="viewAndEdit(popupSettings)">View and
                    edit</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="currentPopup && currentPopupData" class="modal fade" id="update-popup-modal" tabindex="-1" role="dialog"
  aria-labelledby="updatePopupModal" aria-hidden="true" data-backdrop="false">
  <div class="modal-dialog modal-dialog-centered modal-large" role="document">
    <div class="modal-content">
      <form [formGroup]="form">
        <div class="modal-header">
          <h5 class="modal-title">
            Edit popup
          </h5>
        </div>
        <div class="modal-body">
          <div class="form-row" *ngIf="currentPopupData.imageName">
            <div class="form-group col-12">
              <div class="flex-center-center">
                <div class="item-image large">
                  <img [src]="sharedService.getEnvironmentContainer() + '/assets/customPopups/' +
                                    currentPopupData.imageName + '?timestamp=' + timestamp">
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12 mb-0">
              <label>Image (.jpg, .png)</label>
            </div>
            <div class="form-group col-12">
              <div class="custom-file">
                <input type="file" formControlName="file" class="form-control custom-file-input" id="badgeFile2"
                  accept="image/jpeg,image/png" (change)="fileChange($event)">
                <label class="custom-file-label" for="badgeFile2">Choose file</label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <div class="label-group">
                <label>Title (optional)</label>
                <label class="info">Leave blank if not needed</label>
              </div>
              <input type="text" formControlName="title" class="form-control" placeholder="Title"
                [value]="currentPopupData.title" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <div class="label-group">
                <label>Action URL *</label>
                <label class="info" style="margin-bottom: 0;">Clicking on the image sends the user to defined action
                  URL</label>
                <label class="info">Can be a relative URL (e.g., /products/bugs/4, must start
                  with a /), or an external link</label>
              </div>
              <input type="text" formControlName="actionUrl" class="form-control" placeholder="Action URL"
                [value]="currentPopupData.actionUrl" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <div class="label-group" *ngIf="currentPopup.key === 'custom-popup-1'">
                <label>Target URL *</label>
                <label class="info">Appears when the user navigates to defined target URL</label>
              </div>
              <div class="label-group" *ngIf="currentPopup.key === 'custom-popup-2'">
                <label>Target URLs * (separated by a comma)</label>
                <label class="info">Appears when the user navigates to defined target URLs</label>
              </div>
              <input type="text" formControlName="targetUrls" class="form-control" placeholder="Target URLs"
                [value]="currentPopupData.targetUrls" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="displayOneTimePerUser"
                  formControlName="displayOneTimePerUser">
                <label class="custom-control-label" for="displayOneTimePerUser">
                  Display only once per user
                </label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="displayOneTimePerSession"
                  formControlName="displayOneTimePerSession">
                <label class="custom-control-label" for="displayOneTimePerSession">
                  Display once per session
                </label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="showCloseButton"
                  formControlName="showCloseButton">
                <label class="custom-control-label" for="showCloseButton">
                  Show close button
                </label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="isActive" formControlName="isActive">
                <label class="custom-control-label" for="isActive">
                  Is active
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary btn-new-section" (click)="closeUpdatePopupModal()">
            Close
          </button>
          <button class="btn btn-3 btn-new-section" (click)="onUpdate()" [disabled]="isProcessing || form.invalid">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</div>