import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './auth/auth-guard.service'
import { AuthGuardRestrict } from './auth/auth-guard-restrict.service'

import { HomeComponent } from './home/home.component'
import { ProductComponent } from './product/product.component'
import { LoginComponent } from './login/login.component'
import { PrintComponent } from './print/print.component'
import { PdfComponent } from './pdf/pdf.component'
import { SellsheetComponent } from './sellsheet/sellsheet.component'
import { PageNotFoundComponent } from '../core/components/page-not-found/page-not-found.component'
import { AuthComponent } from "./auth/auth/auth.component";

const routes: Routes = [
  { path: 'auth', component: AuthComponent, canActivate: [AuthGuardRestrict] },
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'products/:type/:objectId', component: ProductComponent, canActivate: [AuthGuard] },
  { path: 'print/:fullCatalog/:categoriesIds/:bugsIds', component: PrintComponent },
  { path: 'pdf/:pdfName', component: PdfComponent, canActivate: [AuthGuard] },
  { path: 'sellsheet/:objectId', component: SellsheetComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuardRestrict] },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicRoutingModule { }
