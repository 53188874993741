import { Injectable } from '@angular/core';

import { HomepageSection } from '../interfaces/homepage-section'

import { Query } from 'apollo-angular';
import gql from 'graphql-tag';

export interface FrontpageSections {
  frontpageSections: HomepageSection[];
}

@Injectable({
  providedIn: 'root'
})
export class HomepageSectionService extends Query<FrontpageSections> {
  document = gql`
    query {
      frontpageSections {
        id, name, displayOrder, showBackground items { 
          id, name, imageUrl, targetUrl, displayOrder, badgeKey
        }
      }
    }`;
}
