import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Setting } from '../../interfaces/setting';
import { AdminService } from '../../services/admin.service';

import bsCustomFileInput from 'bs-custom-file-input';
import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment'
import { SharedService } from 'src/app/shared/services/shared.service';
import { CustomPopupService } from 'src/app/shared/services/custom-popup.service';

declare var $: any;

export interface PopupData {
  isActive: boolean;
  title: string | null;
  imageName: string;
  actionUrl: string;
  targetUrls: string;
  displayOneTimePerUser: boolean;
  displayOneTimePerSession: boolean;
  showCloseButton: boolean;
}

@Component({
  selector: 'fc-dashboard-popups',
  templateUrl: './dashboard-popups.component.html',
  styleUrls: ['./dashboard-popups.component.scss']
})
export class DashboardPopupsComponent implements OnInit {

  @Output() showToastEvent = new EventEmitter<any>()

  popupsSettings: Setting[]
  currentPopup: Setting | null = null
  currentPopupData: PopupData | null = null
  timestamp: number = 0
  form: FormGroup
  formSubmitted: boolean = false
  fileData: File = null
  isProcessing: boolean = false

  constructor(
    private _adminService: AdminService,
    private _formBuilder: FormBuilder,
    private _httpClient: HttpClient,
    public sharedService: SharedService,
    private _customPopupService: CustomPopupService
  ) {
    this.form = this._formBuilder.group({
      file: [null],
      title: [null],
      isActive: [false],
      actionUrl: ["", Validators.required],
      targetUrls: ["", Validators.required],
      displayOneTimePerUser: [true],
      displayOneTimePerSession: [true],
      showCloseButton: [true]
    })
  }

  ngOnInit(): void {
    bsCustomFileInput.init()

    this.getSettings()

    this.form.get("displayOneTimePerUser").valueChanges.subscribe(value => {
      if (value === true) {
        this.form.get('displayOneTimePerSession').patchValue(false)
      }
    })

    this.form.get("displayOneTimePerSession").valueChanges.subscribe(value => {
      if (value === true) {
        this.form.get('displayOneTimePerUser').patchValue(false)
      }
    })

    // this.createCustomPopup1()
    // setTimeout(() => {
    //   this.createCustomPopup2()
    // }, 2000);
    // this._adminService.deleteSetting(24).subscribe()
    // this._adminService.deleteSetting(25).subscribe()
  }

  private createCustomPopup1() {
    const data: PopupData = {
      isActive: false,
      title: null,
      imageName: "popup-placeholder.png",
      actionUrl: "/",
      targetUrls: "Any page",
      displayOneTimePerUser: true,
      displayOneTimePerSession: false,
      showCloseButton: true
    }

    this._adminService.createSetting(
      "custom-popup-1",
      JSON.stringify(data),
      "First Pop-Up")
      .subscribe(createSettingData => {
        console.log(createSettingData)
      }, error => {
        console.error('there was an error sending the mutation', error);
      });
  }

  private createCustomPopup2() {
    const data: PopupData = {
      isActive: false,
      title: null,
      imageName: "popup-placeholder.png",
      actionUrl: "/",
      targetUrls: "/",
      displayOneTimePerUser: false,
      displayOneTimePerSession: true,
      showCloseButton: true
    }

    this._adminService.createSetting(
      "custom-popup-2",
      JSON.stringify(data),
      "Second Pop-Up")
      .subscribe(createSettingData => {
        console.log(createSettingData)
      }, error => {
        console.error('there was an error sending the mutation', error);
      });
  }

  getSettings() {
    this.timestamp = Date.now()
    this.popupsSettings = []

    this._adminService.getSettings()
      .subscribe(settingsData => {
        this.popupsSettings = settingsData.filter((e) => {
          return e.key.startsWith('custom-popup')
        })

        // console.log(settingsData)
      }, error => {
        console.error('there was an error sending the query', error);
      });
  }

  viewAndEdit(popupSettings: Setting): void {
    const popupData = JSON.parse(popupSettings.value) as PopupData

    if (!popupData) { return }

    this.currentPopup = popupSettings
    this.currentPopupData = popupData
    this.fileData = null

    this.form.get('file').patchValue(null)
    this.form.get('title').patchValue(popupData.title)
    this.form.get('isActive').patchValue(popupData.isActive)
    this.form.get('actionUrl').patchValue(popupData.actionUrl)
    this.form.get('targetUrls').patchValue(popupData.targetUrls)
    this.form.get('displayOneTimePerUser').patchValue(popupData.displayOneTimePerUser)
    this.form.get('displayOneTimePerSession').patchValue(popupData.displayOneTimePerSession)
    this.form.get('showCloseButton').patchValue(popupData.showCloseButton)

    if (popupSettings.key === "custom-popup-1") {
      this.form.get('targetUrls').disable()
    } else {
      this.form.get('targetUrls').enable()
    }

    setTimeout(() => {
      bsCustomFileInput.destroy()
      bsCustomFileInput.init()

      this.showModal('#update-popup-modal')
    });
  }

  closeUpdatePopupModal() {
    this.hideModal('#update-popup-modal')
    this.currentPopup = null
  }

  onUpdate() {
    if (this.form.invalid) { return }

    this.isProcessing = true

    if (this.fileData !== null && this.fileData !== undefined) {
      this.uploadNewPopupImage(() => {
        this.updateBadge(this.fileData?.name ?? null)
      })
    } else {
      this.updateBadge(this.currentPopupData.imageName ?? null)
    }
  }

  updateBadge(imageName: string) {
    const data: PopupData = {
      isActive: this.form.get('isActive').value ?? false,
      title: this.form.get('title').value && this.form.get('title').value.length > 0 ? this.form.get('title').value : null,
      imageName: imageName,
      actionUrl: this.form.get('actionUrl').value,
      targetUrls: this.form.get('targetUrls').value,
      displayOneTimePerUser: this.form.get('displayOneTimePerUser').value ?? true,
      displayOneTimePerSession: this.form.get('displayOneTimePerSession').value ?? true,
      showCloseButton: this.form.get('showCloseButton').value ?? true
    }

    this._adminService.editSetting(
      parseInt(String(this.currentPopup.id)),
      JSON.stringify(data),
      this.currentPopup.displayName
    )
      .subscribe(updateBadgeData => {
        this._customPopupService.removeCustomPopupInLocalStorage(this.currentPopup.key)
        this.closeUpdatePopupModal()
        this.showToast("Popup updated!")
        this.getSettings()
        this.isProcessing = false
      }, error => {
        console.error('there was an error sending the mutation', error);
        this.showToast("An error during the mutation has occured...")
        this.isProcessing = false
      });
  }

  uploadNewPopupImage(_callback: any) {
    const formData = new FormData();
    formData.append('file', this.fileData);

    this._httpClient.post(environment.endPoint + '/upload/asset?folder=customPopups', formData, {
      reportProgress: true,
      observe: 'events'
    })
      .subscribe(events => {
        if (events.type === HttpEventType.Response) {
          if (events.status === 200) {
            _callback()
          } else {
            console.log('Error during file upload :', events.status, events.body)

            this.showToast("An error during the file upload has occured...")
            this.isProcessing = false
          }
        }
      }, (error: HttpErrorResponse) => {
        console.log('Error during file upload :', error)

        this.showToast("An error during the file upload has occured...")
        this.isProcessing = false
      });
  }

  fileChange(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  private showToast(message: string): void {
    this.showToastEvent.emit(message)
  }

  private showModal(modalRef: string) {
    $("#black-overlay").addClass('visible')
    $(modalRef).modal('show');
  }

  private hideModal(modalRef: string) {
    $("#black-overlay").removeClass('visible')
    $(modalRef).modal('hide');
  }

}
