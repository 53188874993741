import { Component, OnInit } from '@angular/core';

import { SharedService } from 'src/app/shared/services/shared.service'
import { AdminService } from 'src/app/admin/services/admin.service'
import { DevicesService } from 'src/app/core/services/devices.service';

import { HomepageSection } from 'src/app/admin/interfaces/homepage-section';
import { ClientFunctions } from 'src/app/shared/interfaces/client-functions';

declare var $: any;

@Component({
  selector: 'fc-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isMobile: boolean = false
  homepageSettings: {}
  timestamp: number
  homepageSections: HomepageSection[]
  clientFunctions: ClientFunctions

  constructor(
    public sharedService: SharedService,
    private adminService: AdminService,
    private devicesService: DevicesService) { }

  ngOnInit() {
    this.isMobile = this.devicesService.isMobile()
    this.getSettings()
    this.getHomepageSections()
    this.checkIfBrowserIsIE()

    this.timestamp = Date.now()

    this.clientFunctions = {
      mcoe: {
        homepageBottomRightClicked: () => {
          this.navigateToUrl(this.homepageSettings['action-homepage-bottom-right'], null)
        }
      },
      fsv: {
        homepageBottomRightClicked: () => {
        }
      }
    }
  }

  private getSettings(): void {
    this.adminService.getSettings()
      .subscribe(settingsData => {
        this.homepageSettings = settingsData.reduce((result, filter) => {
          result[filter.key] = filter.value;

          return result;
        }, {})
      }, error => {
        console.error('there was an error sending the query', error);
      });
  }

  private getHomepageSections() {
    this.adminService.getHomepageSections()
      .subscribe(homepageSectionsData => {
        this.homepageSections = homepageSectionsData
      }, error => {
        console.error('there was an error sending the query', error);
      });
  }

  navigateToCategoryName(categoryName: string) {
    this.sharedService.navigateToCategoryName(categoryName)
  }

  navigateToCategoryId(categoryId: number) {
    this.sharedService.navigateToCategoryId(categoryId)
  }

  navigateToBugName(bugName: string) {
    this.sharedService.navigateToBugName(bugName)
  }

  navigateToPdfName(pdfName: string) {
    this.sharedService.navigateToPdf(pdfName)
  }

  navigateToUrl(url: string, event) {
    if (url.startsWith('http') || url.startsWith('https')) {
      if (event) {
        event.stopPropagation()
      }

      window.open(url, "_blank");
    } else {
      this.sharedService.navigateToUrl(url)
    }
  }

  private checkIfBrowserIsIE() {
    if (this.sharedService.browserIsIE()) {
      $('#ie-modal').modal('show');
    }
  }

  closeIeModal() {
    $('#ie-modal').modal('hide');
  }
}
