import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import { HomepageCategory } from '../interfaces/homepage-category'

@Injectable({
  providedIn: 'root'
})
export class HomepageCategoryCUDService {
  constructor(private apollo: Apollo) { }

  createCategory(category: HomepageCategory) {
    const createCategory = gql`
      mutation createCategory($category: AddCategoryInput!) {
          createCategory(category: $category) {
              name
          }
      }`;

    return this.apollo.mutate({
      mutation: createCategory,
      variables: {
        category: category
      }
    })
  }

  deleteCategory(category: HomepageCategory) {
    const deleteCategory = gql`
      mutation deleteCategory($category: UpdateCategoryInput!) {
          deleteCategory(category: $category) {
            deletedCategories, deletedProducts
          }
      }`;

    return this.apollo.mutate({
      mutation: deleteCategory,
      variables: {
        category: category
      }
    })
  }

  updateCategory(category: HomepageCategory) {
    const updateCategory = gql`
      mutation updateCategory($category: UpdateCategoryInput!) {
          updateCategory(category: $category) {
              id
          }
      }`;

    return this.apollo.mutate({
      mutation: updateCategory,
      variables: {
        category: category
      }
    })
  }
}
