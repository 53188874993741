import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { PublicService } from "src/app/public/services/public.service";
import { SharedService } from "src/app/shared/services/shared.service";
import { Product } from "../interfaces/product";

@Component({
  selector: "fc-sellsheet",
  templateUrl: "./sellsheet.component.html",
  styleUrls: ["./sellsheet.component.scss"],
})
export class SellsheetComponent implements OnInit {
  tempProducts: Product[] = [];
  products: Product[] = [];

  constructor(
    private publicService: PublicService,
    private route: ActivatedRoute,
    private _sharedService: SharedService
  ) {}

  ngOnInit() {
    document.getElementById("wrapper").style.height = "unset";

    const objectIdParam = this.route.snapshot.paramMap.get("objectId");

    if (objectIdParam) {
      if (this._sharedService.environmentIsFSV()) {
        if (objectIdParam === "full") {
          this.getAllCategoriesWithProducts();
        }
      } else {
        this.getCategoryWithProducts(parseInt(objectIdParam));
      }
    }
  }

  private getCategoryWithProducts(categoryId: number): void {
    this.publicService.getCategoryWithProducts(categoryId).subscribe(
      (categoryWithProductsData) => {
        categoryWithProductsData.children.forEach((categoryDataChildren) => {
          if (categoryDataChildren.products.length > 0) {
            this.sort(categoryDataChildren);
          }

          categoryDataChildren["children"].forEach(
            (categoryDataChildrenChildren) => {
              if (categoryDataChildrenChildren.products.length > 0) {
                this.sort(categoryDataChildrenChildren);
              }
            }
          );
        });

        categoryWithProductsData.children.forEach((categoryDataChildren) => {
          if (categoryDataChildren.products.length > 0) {
            this.tempProducts.push(...categoryDataChildren.products);
          }

          categoryDataChildren["children"].forEach(
            (categoryDataChildrenChildren) => {
              if (categoryDataChildrenChildren.products.length > 0) {
                this.tempProducts.push(
                  ...categoryDataChildrenChildren.products
                );
              }
            }
          );
        });

        this.tempProducts = this.tempProducts.filter(function (e) {
          return e.hasSellSheet === true;
        });

        const productsIds = this.tempProducts.map(product => product.id)
        this.products = this.tempProducts.filter(({ id }, index) => !productsIds.includes(id, index + 1))   
      },
      (error) => {
        console.error("there was an error sending the query", error);
      }
    );
  }

  private getAllCategoriesWithProducts(): void {
    this.publicService.getAllCategoriesWithProducts().subscribe(
      (categoriesWithProductsData) => {
        categoriesWithProductsData.forEach((categoriesWithProductsData) => {
          categoriesWithProductsData.children.forEach(
            (categoryDataChildren) => {
              if (categoryDataChildren.products.length > 0) {
                this.sort(categoryDataChildren);
              }

              categoryDataChildren["children"].forEach(
                (categoryDataChildrenChildren) => {
                  if (categoryDataChildrenChildren.products.length > 0) {
                    this.sort(categoryDataChildrenChildren);
                  }
                }
              );
            }
          );
        });

        categoriesWithProductsData.forEach((categoriesWithProductsData) => {
          categoriesWithProductsData.children.forEach(
            (categoryDataChildren) => {
              if (categoryDataChildren.products.length > 0) {
                this.tempProducts.push(...categoryDataChildren.products);
              }

              categoryDataChildren["children"].forEach(
                (categoryDataChildrenChildren) => {
                  if (categoryDataChildrenChildren.products.length > 0) {
                    this.tempProducts.push(
                      ...categoryDataChildrenChildren.products
                    );
                  }
                }
              );
            }
          );
        });

        this.tempProducts = this.tempProducts.filter(function (e) {
          return e.hasSellSheet === true;
        });

        const productsIds = this.tempProducts.map(product => product.id)
        this.products = this.tempProducts.filter(({ id }, index) => !productsIds.includes(id, index + 1))        
      },
      (error) => {
        console.error("there was an error sending the query", error);
      }
    );
  }

  private sort(element) {
    if (element.products.length > 0) {
      element.products.sort(function (a, b) {
        return a.sortId - b.sortId;
      });
    }
  }
}
