import { Component, OnInit, Input } from '@angular/core';

import { HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';

import { environment } from 'src/environments/environment'

import { SharedService } from 'src/app/shared/services/shared.service'
import { ClientFunctions } from "src/app/shared/interfaces/client-functions";

import { DevicesService } from 'src/app/core/services/devices.service';
import { PublicService } from 'src/app/public/services/public.service';
import { CategoryL1 } from 'src/app/public/services/categories-l1.service';
import { SearchResult } from 'src/app/public/interfaces/search-result';

import { AuthService } from "src/app/public/auth/auth.service";

import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/state/app.state';
import { DisplayStyle, ISiteSettings } from 'src/app/store/models/ISiteSettings';
import { EventBrokerService } from 'src/app/shared/services/eventBroker.service';

declare var $: any;

@Component({
  selector: 'fc-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input() isFsvSecureAccess: boolean

  isMobile: boolean = false
  categoriesL1: CategoryL1[]
  searchTerm$ = new Subject<string>();
  searchResults: SearchResult[]
  showMobileSearchBar: boolean = false
  clientFunctions: ClientFunctions

  allSelectsData = []

  private displayStyle: DisplayStyle = DisplayStyle.Cards;
  public isCardLayout = true;

  constructor(
    public sharedService: SharedService,
    private devicesService: DevicesService,
    private publicService: PublicService,
    private http: HttpClient,
    public authService: AuthService,
    private store: Store<AppState>,
    private eventBroker: EventBrokerService,
  ) {
    this
      .store
      .select(state => state.siteSettings)
      .subscribe((s: ISiteSettings) => {
        this.displayStyle = s ? s.displayStyle : DisplayStyle.Cards;
        this.isCardLayout = this.displayStyle === DisplayStyle.Cards;
      })
      ;
  }

  ngOnInit() {
    this.isMobile = this.devicesService.isMobile()

    this.clientFunctions = {
      mcoe: {
        faqClicked: (arg: string) => {
          this.sharedService.navigateToPdf(arg)
        },
        contactClicked: () => {
          this.sharedService.navigateToPdf('contacts')
        },
        displayHelpModalClicked: () => {
          $('#help-modal').modal('show');
        },
        displayPrintModalClicked: () => {
          this.displayPrintModal()
        },
        inventoryClicked: () => {
          this.displayInventoryModal()
        }
      },
      fsv: {
        faqClicked: (arg: string) => {
          this.sharedService.navigateToPdf('21080_067_FSV_Sales_Merchandising_Catalog_FAQ_RB01')
        },
        contactClicked: () => {
          this.sharedService.navigateToPdf('Merchandising_Catalog_Contact_2021')
        },
        displayHelpModalClicked: () => {
          $('#help-modal').modal('show');
        },
        displayPrintModalClicked: () => {
          this.displayPrintModal()
        },
        inventoryClicked: () => {
          this.displayInventoryModal()
        }
      }
    }
  }

  displayPrintModal() {
    this.publicService.getCategoriesL1()
      .subscribe(categoriesL1Data => {
        this.categoriesL1 = categoriesL1Data

        if (this.sharedService.environmentIsFSV()) {
          var allSelectsData = []

          categoriesL1Data.forEach(l1 => {
            var selectData = []

            l1.children.forEach(l2 => {
              if (l2.children.length > 0) {
                l2.children.forEach(l3 => {
                  selectData.push({ id: l3.id, name: l3.name, level: l2.name, levelId: l2.id })
                });
              } else {
                selectData.push({ id: l2.id, name: "NULL", level: l2.name, levelId: l2.id })
              }
            });

            allSelectsData.push({
              id: l1.id,
              name: l1.name,
              placeholder: l1.name + ' Categories',
              isLoading: false,
              isDisable: true,
              selectData: selectData,
              selectedData: []
            })
          });

          this.allSelectsData = allSelectsData
        }

        // if (this.sharedService.environmentIsMCOE()) {
        //   const largeFormatData = categoriesL1Data.find(x => x.name === "Large Format")?.children?.find(x => x.name === "Big Bets")
        //   if (largeFormatData) {
        //     largeFormatData.name = "Big Bets - Large"
        //     this.categoriesL1.push(largeFormatData)
        //   }

        //   const smallFormatData = categoriesL1Data.find(x => x.name === "Small Format")?.children?.find(x => x.name === "Big Bets")
        //   if (smallFormatData) {
        //     smallFormatData.name = "Big Bets - Small"
        //     this.categoriesL1.push(smallFormatData)
        //   }
        // }

        $('#print-modal').css('opacity', 0)
        $('#print-modal').modal('show');

        setTimeout(() => {
          $('.print-checkbox:checkbox').attr("disabled", false);
          $('.print-checkbox:checkbox').prop("checked", false);
          $('#print-cancel').attr("disabled", false);
          $('#print-print').attr("disabled", false);
          $('#print-print').text('Print PDF');
          $("#black-overlay").addClass('visible')
          $(".print-spinner").css('opacity', 0)

          $('#print-modal').css('opacity', 1)
        }, 500);
      }, error => {
        console.error('there was an error sending the query', error);
      });
  }

  cancelExportToPDF() {
    $("#black-overlay").removeClass('visible')
    $('#print-modal').modal('hide');
  }

  exportToPDF() {
    var categoriesIds = []
    var bugsIds = []
    var fullUrl = ''

    if ($('#full-catalog').is(":checked")) {
      fullUrl += 'full/'

      $('.print-checkbox.l1-categories:checkbox').each(function () {
        if ($(this).attr("id") !== null && $(this).attr("id") !== undefined) {
          if (!$(this).data('titlega').includes('Big Bets')) {
            categoriesIds.push($(this).attr("id"))
          }
        }
      });
    } else {
      fullUrl += 'parts/'
      const self = this
      $('.print-checkbox.l1-categories:checkbox:checked').each(function () {
        if ($(this).attr("id") !== null && $(this).attr("id") !== undefined) {
          if (self.sharedService.environmentIsFSV()) {
            var foundSelectData = self.allSelectsData.find(data => data.id === $(this).attr("id"))

            if (foundSelectData !== undefined) {
              if (foundSelectData.selectedData.length > 0) {
                console.log(foundSelectData)
                foundSelectData.selectedData.forEach(elementId => {
                  categoriesIds.push(elementId)
                });
              } else {
                categoriesIds.push($(this).attr("id"))
              }
            } else {
              categoriesIds.push($(this).attr("id"))
            }
          } else {
            categoriesIds.push($(this).attr("id"))
          }
        }
      });
    }

    $('.print-checkbox.bugs-categories:checkbox:checked').each(function () {
      if ($(this).attr("id") !== null && $(this).attr("id") !== undefined) {
        bugsIds.push($(this).attr("id"))
      }
    });

    if (categoriesIds.length > 0) {
      fullUrl += categoriesIds.join('&')

      if (bugsIds.length > 0) {
        let joinedBugsIds = bugsIds.join('&')

        fullUrl += '/' + joinedBugsIds
      } else {
        fullUrl += '/null'
      }
    } else {
      if (bugsIds.length > 0) {
        let joinedBugsIds = bugsIds.join('&')

        fullUrl += 'null/' + joinedBugsIds
      }
    }

    if (categoriesIds.length > 0 || bugsIds.length > 0) {
      var pdfName = ""

      if (this.sharedService.environmentIsMCOE()) {
        pdfName = 'MCOECatalogExport-' + Date.now() + '.pdf'
      } else if (this.sharedService.environmentIsFSV()) {
        pdfName = 'PepsiCoFoodserviceCatalogExport-' + Date.now() + '.pdf'
      }

      this.generatePDF(fullUrl, pdfName)
    }
  }

  private generatePDF(fullUrl: string, pdfName: string) {
    $('.print-checkbox:checkbox').attr("disabled", true);
    $('#print-cancel').attr("disabled", true);
    $('#print-print').attr("disabled", true);
    $(".print-spinner").css('opacity', 1)
    $('#print-print').text('Generating PDF...');

    let data = {
      printUrl: environment.frontEnd + 'print/' + fullUrl,
      pdfName: pdfName
    }

    this.http.post(environment.endPoint + '/pdf/generate',
      JSON.stringify(data),
      {
        headers: {
          'Content-Type': 'application/json'
        },
        reportProgress: false,
        observe: 'events'
      })
      .subscribe(events => {
        if (events.type === HttpEventType.Response) {
          if (events.status === 200) {
            if (events.body['pdfUrl'] !== undefined && events.body['pdfUrl'] !== null) {
              window.open(events.body['pdfUrl'])
            }

            $("#black-overlay").removeClass('visible')
            $('#print-modal').modal('hide');
          } else {
            console.log('Error during generate pdf :', events.status, events.body)
            $("#black-overlay").removeClass('visible')
            $('#print-modal').modal('hide');
          }
        }
      }, (error: HttpErrorResponse) => {
        console.log('Error during generate pdf :', error)
        $("#black-overlay").removeClass('visible')
        $('#print-modal').modal('hide');
      })
  }

  toggleShowMobileSearchBar() {
    this.showMobileSearchBar = !this.showMobileSearchBar
  }

  cancelSearchClicked() {
    this.toggleShowMobileSearchBar()
  }

  closeHelpModal() {
    $('#help-modal').modal('hide');
  }

  toggleCheckbox(elementId) {
    $('#' + elementId).toggleClass('selected')

    if (this.sharedService.environmentIsFSV()) {
      if (elementId === "full-catalog") {
        this.allSelectsData.forEach(selectData => {
          var toCheck = $('#' + elementId).is(":checked") ? true : false
          $('#' + selectData.id).prop("checked", toCheck);
          selectData.placeholder = $('#' + elementId).is(":checked") ? 'All Categories Selected' : selectData.name + ' Categories'
          selectData.isDisable = $('#' + elementId).is(":checked") ? false : true
        });
      }

      var foundSelectData = this.allSelectsData.find(data => data.id === elementId)

      if (foundSelectData !== undefined) {
        $('#full-catalog').prop("checked", false);

        foundSelectData.isDisable = !foundSelectData.isDisable

        if (foundSelectData.isDisable) {
          foundSelectData.placeholder = foundSelectData.name + ' Categories'
          foundSelectData.selectedData = []
        } else {
          foundSelectData.placeholder = 'All Categories Selected'
        }
      }
    }
  }

  onSelectOpen(selectData) {
    selectData.isLoading = true

    setTimeout(() => {
      $('span').filter(function () { return $(this).text() == 'NULL'; }).parent().remove()
    });

    setTimeout(() => {
      $('.ng-dropdown-panel ').css('display', 'block')
      selectData.isLoading = false
    }, 500);
  }

  groupValueFn = (id: string, children: any[]) => ({ id: children[0].levelId });

  displayInventoryModal() {
    $("#black-overlay").addClass('visible')
    $('#inventory-modal').modal('show');
  }

  switchDisplayStyle() {
    this.store.dispatch({
      type: 'SET_SITE_SETTINGS_DISPLAY_STYLE',
      payload: {
        displayStyle: (this.displayStyle === DisplayStyle.Cards)
          ? DisplayStyle.Rows
          : DisplayStyle.Cards
      }
    });

    setTimeout(() => {
      // Re-emit "productsLoaded" as it will re-compute the total height
      this.eventBroker.emit<void>('productsLoaded', null);
    }, 500);
  }

  goToHome(): void {
    this.sharedService.navigateToUrl('/')
  }
}
