<nav *ngIf="breadcrumbData" aria-label="breadcrumb my-0">
    <ol class="breadcrumb my-0" [ngClass]="{
        'pt-2': !isMobile,
        'pt-0': isMobile,
        'breadcrumb-mobile': isMobile
    }">
        <li class="breadcrumb-item" *ngIf="breadcrumbData.category">
            <button
                class="btn"
                (click)="navigateToUrl(breadcrumbData.category)"
                [ngClass]="{'disabled': !breadcrumbData.categoryChild, 'fsv': sharedService.environmentIsFSV()}"
                [disabled]="breadcrumbData['categoryChild'] === null"
            >
                {{ breadcrumbData.category.name }}
            </button>
        </li>
        <li class="breadcrumb-item" *ngIf="breadcrumbData['categoryChild'] !== null">
            <button class="btn" (click)="navigateToUrl(breadcrumbData['categoryChild'])"
                [ngClass]="{'disabled': breadcrumbData['categoryChildChild'] === null, 'fsv': sharedService.environmentIsFSV()}" [disabled]="breadcrumbData['categoryChildChild'] ===
                null">{{ breadcrumbData['categoryChild']['name'] }}</button>
        </li>
        <li class="breadcrumb-item disabled" *ngIf="breadcrumbData['categoryChildChild'] !== null">
            <button class="btn" (click)="navigateToUrl(breadcrumbData['categoryChildChild'])"
                disabled [ngClass]="{'fsv': sharedService.environmentIsFSV()}">{{ breadcrumbData['categoryChildChild']['name'] }}</button>
        </li>
    </ol>
</nav>