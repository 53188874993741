import { Injectable } from '@angular/core';

import { Query } from 'apollo-angular';
import gql from 'graphql-tag';

export interface Bug {
  id: number;
}

export interface BugByName {
  bugByName: Bug
}

@Injectable({
  providedIn: 'root'
})
export class BugService extends Query<BugByName> {
  document = gql`
    query bugByName($bugName: String!) {
      bugByName(bugName: $bugName) {
        id
      }
    }`;
}
