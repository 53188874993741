<div id="menu-dropdown" *ngIf="menuCategoriesData && isFsvSecureAccess">
    <div id="border-shadow"></div>
    <ul class="dropdown px-1">
        <li class="main_li" *ngIf="sharedService.environmentIsMCOE()">
            <div class="line"></div>
            <a (click)="navigateToBugName('Special Offers')" class="ga-clic-bug-navigationmenu"
                data-titlega="Special Offers">
                Special Offers
            </a>
        </li>
        <li class="main_li">
            <div class="line"></div>
            <a (click)="navigateToCategoryId(-1)" data-titlega="Full Catalog"
                class="ga-clic-product-navigationmenu">Full Catalog</a>
        </li>
        <li *ngFor="let category1 of menuCategoriesData ; let i = index" class="main_li">
            <div class="line"></div>
            <a (click)="navigateToCategoryId(category1.id)" [attr.data-titlega]="category1.name"
                class="ga-clic-product-navigationmenu">
                {{category1.name}}
            </a>
            <ul class="sub_menu" *ngIf="category1.children.length">
                <li *ngFor="let category2 of category1.children" class="sub_li">
                    <div class="sub_menu_container">
                        <div class="link_container">
                            <a (click)="navigateToCategoryId(category2.id)" [attr.data-titlega]="category2.name"
                                class="ga-clic-product-navigationmenu">
                                {{category2.name}}
                            </a>
                            <div class="line2"></div>
                        </div>
                        <ul *ngIf="category2.children.length" [ngClass]="{'left': i > 2}">
                            <li class="sub_li dynamic">
                                <div class="sub_menu_container">
                                    <a class="dynamic"></a>
                                </div>
                            </li>
                            <li *ngFor="let category3 of category2.children" class="sub_li">
                                <div class="sub_menu_container">
                                    <div class="link_container">
                                        <a (click)="navigateToCategoryId(category3.id)"
                                            [attr.data-titlega]="category3.name" class="ga-clic-product-navigationmenu">
                                            {{category3.name}}
                                        </a>
                                        <div class="line2"></div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
                <div class="separator" [ngClass]="{'left': i > 2}"></div>
            </ul>
        </li>
        <!-- <div *ngIf="sharedService.environmentIsMCOE()" style="height: 100%; display: inline-block;">
            <li *ngFor="let category1 of bigBetsLargeMenuCategoriesData ; let i = index"
                class="main_li big-bets-button-container">
                <div class="line"></div>
                <a (click)="navigateToCategoryId(category1.id)" [attr.data-titlega]="'Big Bets - Large'"
                    class="ga-clic-big-bets-category big-bets-button">
                    Big Bets - Large
                </a>
                <ul class="sub_menu" *ngIf="category1.children.length">
                    <li *ngFor="let category2 of category1.children" class="sub_li">
                        <div class="sub_menu_container">
                            <div class="link_container">
                                <a (click)="navigateToCategoryId(category2.id)"
                                    [attr.data-titlega]="'Big Bets - Large - ' + category2.name"
                                    class="ga-clic-big-bets-category">
                                    {{category2.name}}
                                </a>
                                <div class="line2"></div>
                            </div>
                        </div>
                    </li>
                    <div class="separator" [ngClass]="{'left': i > 2}"></div>
                </ul>
            </li>
            <li *ngFor="let category1 of bigBetsSmallMenuCategoriesData ; let i = index"
                class="main_li big-bets-button-container">
                <div class="line"></div>
                <a (click)="navigateToCategoryId(category1.id)" [attr.data-titlega]="'Big Bets - Small'"
                    class="ga-clic-big-bets-category big-bets-button">
                    Big Bets - Small
                </a>
                <ul class="sub_menu" *ngIf="category1.children.length">
                    <li *ngFor="let category2 of category1.children" class="sub_li">
                        <div class="sub_menu_container">
                            <div class="link_container">
                                <a (click)="navigateToCategoryId(category2.id)"
                                    [attr.data-titlega]="'Big Bets - Small - ' + category2.name"
                                    class="ga-clic-big-bets-category">
                                    {{category2.name}}
                                </a>
                                <div class="line2"></div>
                            </div>
                        </div>
                    </li>
                    <div class="separator" [ngClass]="{'left': i > 2}"></div>
                </ul>
            </li>
        </div> -->
        <li class="main_li" *ngIf="sharedService.environmentIsFSV()">
            <div class="line"></div>
            <a (click)="navigateToBugName('Header Cards')" class="ga-clic-bug-navigationmenu"
                data-titlega="Header Cards">
                Header Cards
            </a>
        </li>
        <li class="main_li" *ngIf="sharedService.environmentIsFSV()">
            <div class="line"></div>
            <a (click)="navigateToBugName('Accessories')" class="ga-clic-bug-navigationmenu" data-titlega="Accessories">
                Accessories
            </a>
        </li>
        <!--<li class="main_li" *ngIf="sharedService.environmentIsFSV()">
            <div class="line"></div>
            <a (click)="openSellSheets()">
                Sell Sheets
            </a>
        </li>-->
        <li class="main_li inventory">
            <!--<a class="btn-4 ga-clic-homepage"
                (click)="this.clientFunctions[sharedService.getEnvironmentId()].inventoryClicked()"
                data-titlega="Header - Inventory" *ngIf="sharedService.environmentIsMCOE()">Inventory &<br>Sell
                Sheets</a>-->
            <a class="btn-4 ga-clic-homepage fsv"
                (click)="this.clientFunctions[sharedService.getEnvironmentId()].epopClicked()"
                data-titlega="Header - EPOP">EPOP</a>
        </li>
        <li class="main_li displayStyleSwitch" *ngIf="sharedService.environmentIsFSV()">
            <a class="btn-4" (click)="switchDisplayStyle()">
                <img *ngIf="isCardLayout" src="assets/images/products/CardViewOn.png" class="ga-clic-homepage"
                    data-titlega="Header - Display style - List view" />
                <img *ngIf="!isCardLayout" src="assets/images/products/ListViewOn.png" class="ga-clic-homepage"
                    data-titlega="Header - Display style - Card view" />
            </a>
        </li>
    </ul>
</div>