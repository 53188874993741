import { Injectable } from '@angular/core';

import { Category, CategoryService } from './category.service'
import { CategoriesService } from './categories.service'
import { ProductsCategoriesService } from './products-categories.service'
import { CategoriesL1Service, CategoryL1 } from './categories-l1.service'
import { BugService } from './bug.service'
import { ProductService } from './product.service'
import { ProductsByKeywordService } from './products-by-keyword.service'
import { ProductsByColorService } from './products-by-color.service'
import { ParentCategoryService } from './parent-category.service'
import { Product } from '../interfaces/product'

import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PublicService {

  constructor(
    private categoryService: CategoryService,
    private categoriesService: CategoriesService,
    private productsCategoriesService: ProductsCategoriesService,
    private categoriesL1Service: CategoriesL1Service,
    private bugService: BugService,
    private productService: ProductService,
    private productsByKeywordService: ProductsByKeywordService,
    private productsByColorService: ProductsByColorService,
    private parentCategoryService: ParentCategoryService
  ) { }

  getAllCategoriesWithProducts(): Observable<Category[]> {
    return this.categoriesService.watch()
      .valueChanges
      .pipe(
        first(),
        map(result => result.data.categories)
      );
  }

  getCategoryWithProducts(categoryId: number): Observable<Category> {
    return this.categoryService.watch({ categoryId: categoryId })
      .valueChanges
      .pipe(
        first(),
        map(result => result.data.productsByCategory)
      );
  }

  getProductsByCategories(categoriesIds: any[]): Observable<Category[]> {
    return this.productsCategoriesService.watch({ categoryIds: categoriesIds })
      .valueChanges
      .pipe(
        first(),
        map(result => result.data.productsByCategories)
      );
  }

  getCategoriesL1(): Observable<CategoryL1[]> {
    return this.categoriesL1Service.watch()
      .valueChanges
      .pipe(
        first(),
        map(result => result.data.categories)
      );
  }

  getBugProducts(bugId: number): Observable<Product[]> {
    return this.bugService.watch({ bugId: bugId })
      .valueChanges
      .pipe(
        first(),
        map(result => result.data.productsByBug)
      );
  }

  getProductsByKeyword(keyword: string): Observable<Product[]> {
    return this.productsByKeywordService.watch({ keyword: keyword })
      .valueChanges
      .pipe(
        first(),
        map(result => result.data.productsByKeyword),
      );
  }

  getProductsByColor(colorId: number): Observable<Product[]> {
    return this.productsByColorService.watch({ colorId: colorId })
      .valueChanges
      .pipe(
        first(),
        map(result => result.data.productsByColor)
      );
  }

  getProductByid(productId: number) {
    return this.productService.watch({ productId: productId })
      .valueChanges
      .pipe(
        first(),
        map(result => result.data['product'])
      );
  }

  getParentCategory(categoryId: number) {
    return this.parentCategoryService.watch({ categoryId: categoryId })
      .valueChanges
      .pipe(
        first(),
        map(result => result.data.getParentCategory)
      );
  }
}
