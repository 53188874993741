import { Injectable } from '@angular/core'
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { AuthService } from "./auth.service";
import { SharedService } from "src/app/shared/services/shared.service";

import { Subject } from 'rxjs'

@Injectable()

export class AuthGuard implements CanActivate {
    isFsvSecureAccess: boolean
    checkFsvSecureAccessSubject: Subject<boolean> = new Subject<boolean>();

    constructor(
        private authService: AuthService,
        private router: Router,
        private sharedService: SharedService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.checkFsvSecureAccess()
    }

    checkFsvSecureAccess(): boolean {
        if (this.sharedService.environmentIsMCOE()) {
            this.isFsvSecureAccess = true
            this.checkFsvSecureAccessSubject.next(this.isFsvSecureAccess)

            return true
        } else if (this.sharedService.environmentIsFSV()) {
            // this.isFsvSecureAccess = this.authService.isFsvSecureAccess()
            // this.checkFsvSecureAccessSubject.next(this.isFsvSecureAccess)

            // if (this.isFsvSecureAccess) { return true }

            this.isFsvSecureAccess = true
            this.checkFsvSecureAccessSubject.next(this.isFsvSecureAccess)

            return true
        }

        this.router.navigate(['/auth'])

        return false
    }
}
