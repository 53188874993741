import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { tap, delay } from 'rxjs/operators'

import { SharedService } from "src/app/shared/services/shared.service";

import * as CryptoJS from 'crypto-js';

@Injectable()
export class AuthService {
    private secretKey = "PepsiCo-Foodservice"
    private password = "AFHfritolay20"
    private cookieName = "fsvSecureAccessExpirationTime"
    private cookieMaxDays = 14

    constructor(
        private sharedService: SharedService
    ) { }

    login(password: string): Observable<boolean> {
        let isPasswordsMatching = password === this.password

        return of(true)
            .pipe(
                delay(100),
                tap(val => {
                    if (isPasswordsMatching) {
                        //this.createCookie()

                        var ciphertext = CryptoJS.AES.encrypt(password, this.secretKey).toString();
                        //localStorage.setItem("fsvSecureAccess", ciphertext)
                        sessionStorage.setItem("fsvSecureAccess", ciphertext)
                    }
                })
            )
    }

    isFsvSecureAccess(): boolean {
        if (this.sharedService.environmentIsFSV()) {
            //var fsvSecureAccessStorageValue = window.localStorage.getItem("fsvSecureAccess")
            var fsvSecureAccessStorageValue = window.sessionStorage.getItem("fsvSecureAccess")

            if (fsvSecureAccessStorageValue !== null) {
                var bytes  = CryptoJS.AES.decrypt(fsvSecureAccessStorageValue, this.secretKey);
                var originalText = bytes.toString(CryptoJS.enc.Utf8);

                if (originalText === this.password) {
                    /*if (this.cookieIsValid()) {
                        return true
                    }*/

                    return true
                }
            }

            return false
        }

        return true
    }

    private createCookie() {
        var date = new Date()
        date.setTime(date.getTime() + (this.cookieMaxDays * 24 * 60 * 60 *1000))
        
        var expires = "; expires=" + date.toUTCString()

        document.cookie = this.cookieName + "=1" + expires + "; path=/"
    }

    private cookieIsValid() {
        if (document.cookie && document.cookie.indexOf(this.cookieName + "=1") !== -1) {
            return true
        } else {
            return false
        }
    }
}