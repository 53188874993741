import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { BreadcrumbComponent } from "../public/product/breadcrumb/breadcrumb.component";
import { setBreadCrumbReducer } from "../store/reducers/BreadCrumb.reducer";
import { setSiteSettingsReducer } from "../store/reducers/SiteSettings.reducer";
import { MenuTreeComponent } from "./components/menu/menu-tree/menu-tree.component";
import { ScrollSpyDirective } from "./scroll-spy.directive";
import { SizeSpyDirective } from "./size-spy.directive";
import { EventBrokerService } from "./services/eventBroker.service";
import { CustomPopupComponent } from "./components/custom-popup/custom-popup.component";

@NgModule({
  providers: [EventBrokerService],
  declarations: [ScrollSpyDirective, SizeSpyDirective, MenuTreeComponent, BreadcrumbComponent, CustomPopupComponent],
  imports: [
    CommonModule,
    StoreModule.forRoot({
      breadCrumb: setBreadCrumbReducer,
      siteSettings: setSiteSettingsReducer
    })
  ],
  exports: [ScrollSpyDirective, SizeSpyDirective, MenuTreeComponent, BreadcrumbComponent, CustomPopupComponent]
})
export class SharedModule { }
