import { Injectable } from '@angular/core';

import { Query } from 'apollo-angular';
import gql from 'graphql-tag';

export interface Category {
  id: number;
  name?: string
  parent?: Category
}

export interface CategoryByName {
  categoryByName: Category
}

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends Query<CategoryByName> {
  document = gql`
    query categoryByName($categoryName: String!) {
      categoryByName(categoryName: $categoryName) {
        id
      }
    }`;
}
