import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { DashboardComponent } from './dashboard.component';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { DashboardHomepageComponent } from './dashboard-homepage/dashboard-homepage.component';
import { UploadModalComponent } from './upload-modal/upload-modal.component';
import { DashboardMainDataComponent } from './dashboard-main-data/dashboard-main-data.component';
import { DashboardHeadersAndSectionsComponent } from './dashboard-homepage/dashboard-headers-and-sections/dashboard-headers-and-sections.component';
import { DashboardCategoriesComponent } from './dashboard-categories/dashboard-categories.component';
import { DashboardActionsComponent } from './dashboard-homepage/dashboard-actions/dashboard-actions.component';
import { DashboardBadgesComponent } from './dashboard-homepage/dashboard-badges/dashboard-badges.component';
import { DashboardSellsheetComponent } from './dashboard-sellsheet/dashboard-sellsheet.component';
import { DashboardPopupsComponent } from './dashboard-popups/dashboard-popups.component';

@NgModule({
  declarations: [DashboardComponent, DashboardHomepageComponent, UploadModalComponent, DashboardMainDataComponent, DashboardHeadersAndSectionsComponent, DashboardCategoriesComponent, DashboardActionsComponent, DashboardBadgesComponent, DashboardSellsheetComponent, DashboardPopupsComponent],
  imports: [
    CommonModule,
    AngularFileUploaderModule,
    ReactiveFormsModule
  ]
})
export class DashboardModule { }
