import { Injectable } from '@angular/core';

import { Query } from 'apollo-angular';
import gql from 'graphql-tag';

export interface Category {
  id: number;
  name: string;
  parent: Category;
}

export interface GetParentCategory {
  getParentCategory: Category
}

@Injectable({
  providedIn: 'root'
})
export class ParentCategoryService extends Query<GetParentCategory> {
  document = gql`
    query getParentCategory($categoryId: Float!) {
      getParentCategory(categoryId: $categoryId) {
        id, name, parent {
          id, name, parent {
            id, name
          }
        }
      }
    }`;
}
