import { IBreadCrumb } from '../models/IBreadCrumb';
import { Action } from '@ngrx/store';

export const SET_BREADCRUMB = 'SET_BREADCRUMB';

export function setBreadCrumbReducer(state: IBreadCrumb, action) {
  switch (action.type) {
    case SET_BREADCRUMB:
        return action.payload;
    default:
        return state;
    }
}