import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardAdmin } from './auth-guard.service'
import { AuthGuard } from 'src/app/public/auth/auth-guard.service'

import { DashboardComponent } from './dashboard/dashboard.component'

const routes: Routes = [
  { path: 'admin/dashboard', component: DashboardComponent, canActivate: [AuthGuard, AuthGuardAdmin] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
