<div class="row header">
    <div class="col-12 px-0">
        <div class="chapter" [ngClass]="{'fsv': sharedService.environmentIsFSV()}">
            <div class="title">
                {{ categoryName }}
            </div>
            <div class="header" *ngIf="categoryChildName" [ngClass]="{'fsv': sharedService.environmentIsFSV()}">
                <div class="title">
                    {{ categoryChildName }}
                </div>
                <div class="subheader" *ngIf="categoryChildChildName" [ngClass]="{'fsv': sharedService.environmentIsFSV()}">
                    <div class="title">
                        {{ categoryChildChildName }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="divider my-3" *ngIf="displayDivider"></div>
</div>