<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" href="#">
        <img src="assets/favicon/favicon-57.png" width="40" height="40" class="d-inline-block align-middle" alt="">
        Dashboard
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item">
                <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true"
                    *ngIf="sharedService.environmentIsMCOE()">
                    Connected as MCOE administrator </a>
                <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true"
                    *ngIf="sharedService.environmentIsFSV()">
                    Connected as PepsiCo administrator </a>
            </li>
        </ul>
    </div>
    <div class="my-2 my-lg-0">
        <button type="button" class="btn btn-1" (click)="logout()">Logout</button>
    </div>
</nav>

<div class="row mt-3">
    <div class="col-2">
        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <a class="nav-link active" id="v-pills-data-management-tab" data-toggle="pill"
                href="#v-pills-data-management" role="tab" aria-controls="v-pills-data-management" aria-selected="true">
                Main Data Management
            </a>
            <a class="nav-link" id="v-pills-homepage-tab" data-toggle="pill" href="#v-pills-homepage" role="tab"
                aria-controls="v-pills-homepage" aria-selected="false">
                Homepage Content
            </a>
            <a class="nav-link" id="v-pills-categories-tab" data-toggle="pill" href="#v-pills-categories" role="tab"
                aria-controls="v-pills-categories" aria-selected="false">
                Categories
            </a>
            <a class="nav-link" id="v-pills-sellsheet-tab" data-toggle="pill" href="#v-pills-sellsheet" role="tab"
                aria-controls="v-pills-sellsheet" aria-selected="false">
                Sell Sheets Status
            </a>
            <a class="nav-link" id="v-pills-popups-tab" data-toggle="pill" href="#v-pills-popups" role="tab"
                aria-controls="v-pills-popups" aria-selected="false">
                Popups
            </a>
        </div>
    </div>
    <div class="col-10">
        <div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="v-pills-data-management" role="tabpanel"
                aria-labelledby="v-pills-data-management-tab">
                <fc-dashboard-main-data (showUploadModalEvent)="showUploadModal($event)"></fc-dashboard-main-data>
            </div>
            <div class="tab-pane fade" id="v-pills-homepage" role="tabpanel" aria-labelledby="v-pills-homepage-tab">
                <fc-dashboard-homepage (showUploadModalEvent)="showUploadModal($event)"
                    (showToastEvent)="showToast($event)" [closeModalEvent]="closeModalSubject.asObservable()">
                </fc-dashboard-homepage>
            </div>
            <div class="tab-pane fade" id="v-pills-categories" role="tabpanel" aria-labelledby="v-pills-categories-tab">
                <fc-dashboard-categories (showToastEvent)="showToast($event)"></fc-dashboard-categories>
            </div>
            <div class="tab-pane fade" id="v-pills-sellsheet" role="tabpanel" aria-labelledby="v-pills-sellsheet-tab">
                <fc-dashboard-sellsheet></fc-dashboard-sellsheet>
            </div>
            <div class="tab-pane fade" id="v-pills-popups" role="tabpanel" aria-labelledby="v-pills-popups-tab">
                <fc-dashboard-popups (showToastEvent)="showToast($event)"></fc-dashboard-popups>
            </div>
        </div>
    </div>
</div>

<div id="adminToast" class="toast" data-delay="3000" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
        <strong class="mr-auto"><i class="fa fa-grav"></i>Dashboard</strong>
    </div>
    <div class="toast-body" [innerHTML]="toastMessage">
    </div>
</div>

<fc-upload-modal *ngIf="modalContent" [modalContent]="modalContent" (showToastEvent)="showToast($event)"
    (closeModalEvent)="closeModalEvent()">
</fc-upload-modal>