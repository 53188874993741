import { Component, OnInit, Input, Output, AfterViewInit, EventEmitter } from '@angular/core';

import { SharedService } from 'src/app/shared/services/shared.service'
import { DisplayStyle, ISiteSettings } from 'src/app/store/models/ISiteSettings';
import { AppState } from 'src/app/store/state/app.state';
import { Store } from '@ngrx/store';
import { ProductProperties } from '../../interfaces/product-properties';

declare var $: any;

@Component({
  selector: 'fc-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit, AfterViewInit {
  @Input() product: any;
  @Input() isPrint: boolean;
  @Input() categoryId: number;

  @Output() downloadCardClicked = new EventEmitter<any>()
  @Output() thumbnailClicked = new EventEmitter<any>()

  productName: string
  productHas360 = false
  imageName = ""
  titleGA = ""

  public isCardLayout = true;

  constructor(
    private store: Store<AppState>,
    public sharedService: SharedService) {
    this
      .store
      .select(state => state.siteSettings)
      .subscribe((s: ISiteSettings) => {
        this.isCardLayout = s ? s.displayStyle === DisplayStyle.Cards : false;
      })
      ;
  }

  ngOnInit() {
    this.highlightNotesIfNeeded()

    try {
      const productProperties: ProductProperties = JSON.parse(this.product.properties)

      this.productHas360 = productProperties.has360View === true ? true : false
    } catch (e) {
      this.productHas360 = false
    }

    if (this.product.productColors[0].fullEqi !== null) {
      this.imageName = `${this.sharedService.getEnvironmentContainer()}/images-products/thumbs/${this.product.productColors[0].fullEqi}x300.jpg`
      this.titleGA = `${this.product.productColors[0].fullEqi}- ${this.product.name}`
    } else {
      this.imageName = `${this.sharedService.getEnvironmentContainer()}/images-products/thumbs/${this.product.eqi}x300.jpg`
      this.titleGA = `${this.product.eqi}- ${this.product.name}`
    }
  }

  ngAfterViewInit(): void {
    $('[data-toggle="tooltip"]').tooltip()
  }

  navigateToBugId(bugId: number) {
    $('.badge').tooltip('hide')

    this.sharedService.navigateToBugId(bugId)
  }

  download() {
    this.downloadCardClicked.emit({ product: this.product, categoryId: this.categoryId })
  }

  private highlightNotesIfNeeded() {
    if (this.product && this.product.bugs) {
      if (this.product.bugs.filter(function (e) { return e.id === '4'; }).length > 0) {
        if (this.product.notes) {
          this.product.notes = this.product.notes.replace('<hi>', '<div class="badge badge-pill badge-warning">')
          this.product.notes = this.product.notes.replace('</hi>', '</div><div>')
          this.product.notes += '</div>';
        }
      }
    }
  }

  showThumbnailModal() {
    this.thumbnailClicked.emit(this.product)
  }
}
