import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';

import bsCustomFileInput from 'bs-custom-file-input';

import { environment } from 'src/environments/environment'
import { AdminService } from '../../../services/admin.service'
import { Setting } from '../../../interfaces/setting';

import { SharedService } from "src/app/shared/services/shared.service";

declare var $: any;

@Component({
  selector: 'fc-dashboard-badges',
  templateUrl: './dashboard-badges.component.html',
  styleUrls: ['./dashboard-badges.component.scss']
})
export class DashboardBadgesComponent implements OnInit {
  @Input() homepageSettings: Setting[]
  @Output() showToastEvent = new EventEmitter<any>()
  @Output() needToRefreshData = new EventEmitter<any>()

  timestamp: number
  badgeForm: FormGroup
  badgeFormSubmitted: boolean = false
  badges: Setting[]
  currentBadge: Setting = null
  badgeFileData: File = null

  constructor(
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private httpClient: HttpClient,
    public sharedService: SharedService
  ) { }

  ngOnInit() {
    bsCustomFileInput.init()

    this.initBadgeForm()
    this.getBadgesSettings()
  }

  private getBadgesSettings() {
    this.timestamp = Date.now()

    this.badges = []
    this.badges = this.homepageSettings.filter(function (e) {
      return e.key.startsWith('badge-homepage')
    })
  }

  private initBadgeForm() {
    this.badgeForm = this.formBuilder.group({
      badgeFile: [null, Validators.required],
      badgeName: ['', Validators.required]
    })
  }

  private showToast(message: string): void {
    this.showToastEvent.emit(message)
  }

  private showModal(modalRef: string) {
    $("#black-overlay").addClass('visible')
    $(modalRef).modal('show');
  }

  private hideModal(modalRef: string) {
    $("#black-overlay").removeClass('visible')
    $(modalRef).modal('hide');
  }

  badgeFileChange(fileInput: any) {
    this.badgeFileData = <File>fileInput.target.files[0];
  }

  uploadNewBadgeImage(_callback) {
    const formData = new FormData();
    formData.append('file', this.badgeFileData);

    this.httpClient.post(environment.endPoint + '/upload/asset?folder=homepageBadges', formData, {
      reportProgress: true,
      observe: 'events'
    })
      .subscribe(events => {
        if (events.type === HttpEventType.Response) {
          if (events.status === 200) {
            _callback()
          } else {
            console.log('Error during file upload :', events.status, events.body)

            this.showToast("An error during the file upload has occured...")
          }
        }
      }, (error: HttpErrorResponse) => {
        console.log('Error during file upload :', error)

        this.showToast("An error during the file upload has occured...")
      });
  }

  /* ADD */

  addBadge() {
    this.initBadgeForm()
    this.badgeForm.reset()
    this.badgeFormSubmitted = false
    this.badgeFileData = null

    setTimeout(() => {
      bsCustomFileInput.destroy()
      bsCustomFileInput.init()
      this.showModal('#add-new-badge-modal')
    });
  }

  onAddNewBadgeSubmit() {
    this.badgeFormSubmitted = true;

    if (this.badgeForm.invalid) {
      return
    }

    this.uploadNewBadgeImage(() => {
      this.createBadge()
    })
  }

  private createBadge() {
    var cleanBadgeName = this.badgeForm.controls.badgeName.value.replace(/[^A-Z0-9]+/ig, "-").toLowerCase()

    this.adminService.createSetting(
      "badge-homepage-" + cleanBadgeName,
      this.badgeFileData.name,
      this.badgeForm.controls.badgeName.value)
      .subscribe(createSettingData => {
        this.needToRefreshData.emit()
        this.closeNewBadgeModal()
        this.showToast('New badge created!')
        this.getBadgesSettings()
      }, error => {
        console.error('there was an error sending the mutation', error);
      });
  }

  closeNewBadgeModal() {
    this.hideModal('#add-new-badge-modal')
  }

  /* DELETE */

  showDeleteBadgeModal(badge: Setting) {
    this.currentBadge = badge

    setTimeout(() => {
      this.showModal('#delete-badge-modal')
    });
  }

  closeDeleteBadgeModal() {
    this.hideModal('#delete-badge-modal')
    this.currentBadge = null
  }

  deletebadge(badgeId) {
    this.adminService.deleteSetting(parseInt(badgeId))
      .subscribe(deleteSettingData => {
        if (deleteSettingData.data['deleteSetting'] === true) {
          this.needToRefreshData.emit()
          this.closeDeleteBadgeModal()
          this.showToast("Badge deleted!")
          this.getBadgesSettings()
        } else {
          console.error('there was an error sending the mutation')
          this.showToast("An error during the mutation has occured...")
        }
      }, error => {
        console.error('there was an error sending the mutation', error);
        this.showToast("An error during the mutation has occured...")
      });
  }

  /* UPDATE */

  showUpdateBadgeModal(badge: Setting) {
    this.currentBadge = badge
    this.badgeFormSubmitted = false
    this.badgeFileData = null
    this.badgeForm = this.formBuilder.group({
      badgeFile: [null],
      badgeName: [this.currentBadge.displayName, Validators.required]
    })

    setTimeout(() => {
      bsCustomFileInput.destroy()
      bsCustomFileInput.init()
      this.showModal('#update-badge-modal')
    });
  }

  closeUpdateBadgeModal() {
    this.hideModal('#update-badge-modal')
    this.currentBadge = null
  }

  onUpdateBadgeSubmit() {
    this.badgeFormSubmitted = true;

    if (this.badgeForm.invalid) {
      return
    }

    if (this.badgeFileData !== null && this.badgeFileData !== undefined) {
      this.uploadNewBadgeImage(() => {
        this.updateBadge(this.badgeFileData.name)
      })
    } else {
      this.updateBadge(this.currentBadge.value)
    }
  }

  updateBadge(badgeFileName: string) {
    this.adminService.editSetting(
      parseInt(String(this.currentBadge.id)),
      badgeFileName,
      this.badgeForm.controls.badgeName.value
    )
      .subscribe(updateBadgeData => {
        this.needToRefreshData.emit()
        this.closeUpdateBadgeModal()
        this.showToast("Badge updated!")
        this.getBadgesSettings()
      }, error => {
        console.error('there was an error sending the mutation', error);
        this.showToast("An error during the mutation has occured...")
      });
  }
}
